export default {
    content: {
        appTitle: 'AIbox后台管理系统',
        // request请求
        loading: '加载中',
        noAuth: '您现在没有权限，请先登录',

        // 通用
        general: {
            inputPlaceholder: '请输入搜索内容',
            deleteTip: '您确定要删除吗',
            deleteSuccess: '删除成功',
            addSuccess: '添加成功',
            editSuccess: '修改成功'
        },
        // 路由
        router: {
            empty: '',
            login: '登录',
            license: 'License证书',
            dataPanel: '数据看板',
            idManage: '点位管理',
            idConfig: '点位配置',
            guard: '智能布控',
            people: '人员库',
            carPlate: '车牌库',
            licensePlate: '车牌库',
            workCloth: '工服库',
            controlTask: '布控任务',
            controlData: '布控数据',
            analysis: '分析统计',
            behavioralEvent: '行为事件',
            trafficEvent: '交通事件',
            peopleCounting: '人数统计',
            fullStructuredSnapshot: '全结构化抓拍',
            search: '数据检索',
            behaviorSearch: '数据检索',
            eventsLog: '事件记录',
            workClothSnapshot: '工服抓拍',
            targetSearch: '目标查询',
            PK: '扩展配置',
            dataDock: '数据对接',
            system: '系统配置',
            userConfig: '用户配置',
            roleConfig: '角色配置',
            defender: '维护',
            versionInfo: '版本信息',
            operationLog: '操作日志',
            timeConfig: '时间配置',
            operatorManage: '算子管理',
            personalConfig: '个性化配置',
            dataSend: '数据上报',
            networkGrabbing: '网络抓包',
            securityConfig: '安全中心',
            resourceList: '资源占用列表',
            basicConfig: '基础配置',
            serviceList: '服务列表',
            apiStatus: '接口状态',
            networkConfig: '网络配置',
            ipConfig: 'TCP/IP',
            npsConfig: 'NPS配置',
            planLinkage: '布防联动'
        },
        // navbar
        changeLang: {
            zh: '简体中文',
            en: '英文',
            message: '切换成功'
        },
        changeTheme: '更换皮肤',
        navBar: {
            modifyPwd: '修改密码',
            logout: '退出',
            logoutTips: '确认退出登录吗？'
        },
        // 按钮
        button: {
            add: '添加',
            edit: '编辑',
            delete: '删除',
            refresh: '刷新',
            close: '关闭',
            cancel: '取消',
            confirm: '确定',
            export: '导出',
            save: '保存',
            skip: '跳过',
            search: '搜索',
            query: '查询',
            reset: '重置',
            picQualityConfig: '图片质量配置',
            open: '开启',
            new: '新增',
            autoSearch: '自动搜索',
            batchDelete: '批量删除',
            downloadTemplate: '下载模板',
            batchImportChannel: '批量导入点位',
            batchExportChannel: '批量导出点位',
            config: '智能配置',
            info: '详情'
        },

        // 退出登录
        logout: {
            button: '退出登录'
        },
        // 修改密码
        modifyPwd: {
            title: '修改密码',
            oldPassword: '旧密码',
            newPassword: '新密码',
            usernamePlaceholder: '请输入用户名',
            confirmNewPassword: '确认新密码',
            oldPasswordPlaceholder: '请输入旧密码',
            newPasswordPlaceholder: '请输入新密码',
            confirmNewPasswordPlaceholder: '请确认新密码',
            pwdStrength: '密码强度',
            pwdStrengthFirst: '无',
            pwdStrengthSecond: '弱',
            pwdStrengthThird: '中',
            pwdStrengthFourth: '强',
            pwdNull: '密码不能为空',
            pwdChinese: '密码不能含有中文',
            pwdSpace: '密码不能含有空格',
            pwd620: '密码位数为6到20位',
            confirmPwdEmpty: '确认密码不能为空',
            confirmPwdSame: '确认密码需与密码一致',
            originPwdNull: '原始密码不能为空'
        },
        // 登录
        login: {
            welcome: '欢迎登录',
            account: '账号',
            pwd: '密码',
            button: '登录',
            messageSuccess: '登录成功',
            messageError: '登录失败',
            accountPlaceholder: '请输入账号',
            passwordPlaceholder: '请输入密码',
            forgetPwd: '忘记密码',
            confirmPwdPlaceholder: '请确认密码',
            confirmPwdValidator: '两次输入的密码不一致',
            register: '注册',
            registerSuccess: '注册成功',
            authFail: '权限不足',
            usernameOrPwdIsEmpty: '用户名或密码不能为空',
            noPwdProtect: '未设置密保，无法使用忘记密码功能',
            question: '问题',
            answer: '答案',
            newPwd: '新密码',
            confirmPwd: '确认密码',
            pwdResetSuccess: '密码重置成功',
            loginIng: '登录中',
            next: '下一步',
            confirmModify: '确认修改',
            pwdNoEmpty: '密码不能为空',
            nameNoEmpty: '用户名不能为空',
            pwd620: '密码位数为6到20位',
            confirmPwdNoEmpty: '确认密码不能为空',
            confirmPwdSame: '确认密码需与密码一致',
            questionMsg: '请选择问题',
            tryAgain: '请刷新后重试！',
            verifyPermission: '请先验证权限密码',
            validateError: '不可选择同一问题',
            verifySuccess: '权限密码验证成功',
            verifyFailed: '权限密码验证失败',
            loginError: '传输信息有误！',
            usernamePlaceholder: '请输入用户名'
        },
        // license
        license: {
            title: 'License授权',
            step1: '若无硬件指纹文件，请先申请硬件指纹文件，然后申请证书',
            step2: '若已申请硬件指纹文件，请直接上传申请的证书文件',
            generateFinger: '生成硬件指纹',
            chooseFile: '选择文件',
            upload: '上传',
            uploadSuccess: '上传成功',
            fileEmpty: '请选择文件',
            uploadError: '上传失败',
            downloadSuccess: '下载成功',
            loading: '上传中...'
        },
        // 点位管理
        idManage: {
            title: '点位管理',
            channelName: '点位名称',
            streamStatus: '视频流状态',
            online: '在线',
            offline: '离线',
            ipAddress: 'IP地址',
            warnType: '告警类型',
            operate: '操作',
            noChannel: '暂无点位',
            exportChannel: '导出点位',
            exportType: '导出类型',
            exportOption1: '全部导出',
            exportOption2: '本页导出',
            exportOption3: '选中导出',
            chooseChannelTip: '请选择导出点位',
            exportSuccess: '导出成功',
            chooseChannel: '请选择点位',
            addChannel: '添加点位',
            chooseBitStream: '码流选择',
            protocol: '协议',
            protocolTips: '请选择协议',
            id: '通道号',
            port: '端口',
            user: '用户名',
            pwd: '密码',
            // 新增点位
            transport: '传输协议',
            transportTips: '请选择传输协议',
            channelLimit: '通道已满',
            mode1: '鉴权模式',
            mode2: '非鉴权模式',
            deviceInfo: '设备信息',
            serialNumber: '序列号',
            company: '厂商',
            model: '型号',
            stream1: '主码流',
            stream2: '辅码流',
            stream3: '第三流',
            encode: '编码格式',
            ratio: '分辨率',
            rtsp: 'rtsp',
            fps: '帧率',
            kbps: '码率',
            quality: '质量',
            next: '下一步',
            back: '上一步',
            protocolErr: '协议不能为空',
            transportErr: '传输类型不能为空',
            usernameErr: '用户名不能为空',
            pwdErr: '密码不能为空',
            nameErr1: '点位名称不能为空',
            nameErr2: '点位名称不得超过30个字符',
            ipErr1: 'IP地址不能为空',
            ipErr2: 'IP地址不正确',
            portErr1: '端口不能为空',
            portErr2: '端口只能为数字',
            rtspErr: 'rtsp不能为空',
            // 编辑点位
            editChannel: '编辑点位',
            // 自动搜索
            type: '类型',
            ifAdd: '是否已添加',
            ifAdd1: '已被添加',
            ifAdd2: '未被添加',
            auto: '自动搜索点位',
            // 批量导入点位
            batchImportChannel: '批量导入点位',
            upload: '上传文件',
            uploadTip: '注：请先下载模板或导出点位，并按要求填写',
            uploadErr1: '请上传文件',
            number: '序号为',
            importErr: '的点位导入失败',
            importSuccess: '导入成功',
            // 点位详情
            channelInfo: '点位详情',
            status: '状态',
            // 点位配置
            syncChannel: '同步点位',
            saveVideo: '保存告警视频',
            ifOpen: '是否开启',
            beforeWarn: '告警前',
            afterWarn: '告警后',
            warnSwitch: '告警恢复开关',
            ruleConfig: '区域设置',
            guardConfig: '布防时间',
            linkageplan: '联动计划',
            chooseFunction: '选择功能',
            chooseAll: '全选',
            syncTo: '同步到',
            addFunction: '添加功能',
            warnErr1: '告警时间不能为空',
            warnErr2: '告警前后时间相加不得超过60s',
            warnErr3: '告警前、后时间不得小于5s',
            saveErr1: '请先保存该算法配置',
            syncErr1: '请先操作完该算法再同步点位',
            syncErr2: '请选择功能',
            syncErr3: '请选择点位',
            syncSuccess: '同步点位成功',
            channel: '点位',
            syncComplete: '同步完成',
            syncFail: '同步失败',
            addErr1: '请先操作完该算法再添加',
            removeSuccess: '移除成功',
            addErr2: '请选择要添加的功能模块',
            // 区域设置
            paintPolygon: '绘制区域',
            paintLine: '绘制直线',
            clearRule: '清除规则',
            paintDes: '绘制说明',
            des1: '左键进行绘制，右键结束绘制过程',
            des2: '绘制直线时，最少2个点',
            des3: '绘制区域时，最少3个点，最多6个点',
            des4: '绊线人数统计业务需在绘制完直线后，再次点击绘制方向线',
            des5: '攀高检测业务需在绘制完区域后，再次点击绘制高度线',
            attr: '属性配置',
            time: '触发时间',
            person: '人',
            personLimit: '人数限制',
            background: '背景刷新时间',
            second: '秒',
            clock: '点',
            personFloat: '人数浮动',
            workCloth: '工服库',
            workClothPlaceholder: '请选择工服库',
            paintErr1: '区域与直线数量不一致',
            paintErr2: '请选择工服库',
            paintErr3: '直线与方向数量不一致',
            paintErr4: '直线与区域数量需保持一致',
            paintErr5: '请先绘制区域',
            saveSuccess: '保存成功',
            saveFail: '保存失败',
            syncRule: '同步规则',
            toChannel: '接受点位',
            // 布防时间
            copy: '复制到本周',
            clearAll: '全部清空',
            day1: '周一',
            day2: '周二',
            day3: '周三',
            day4: '周四',
            day5: '周五',
            day6: '周六',
            day7: '周天',
            chooseOneDay: '请选择一天',
            copyChannel: '复制时间段到通道',
            copyChannelPlaceholder: '请选择通道',
            eventType: '事件类型',
            algoTypePlaceholder: '请选择算法类型',
            all: '全部',
            //联动计划
            linkagePlanType1: '常规联动',
            linkagePlanType2: '联动报警输出',
            open: '开启',
            //算力计算
            residualPowerTitle: '当前算力值剩余：',
            residualPowerError: '算力不足，请合理配置智能业务',
            close: '关闭',
            faceCatch: '人脸抓拍',
            direction: '检测方向',
            detourarea: '绕行区域',
            line: '直线',
            directionTip: '请选择检测方向',
            detourareaTip: '请选择绕行区域配置',
            videoSave: '告警视频保存',
            faceenable: '人脸检测使能',
            personenable: '人体检测使能',
            vehicleenable: '机动车检测使能',
            nonvehicleenable: '非机动车检测使能',
            noPutEventTime: '未盖垃圾桶触发时间',
            videoBefore: '告警前视频时长',
            videoAfter: '告警后视频时长',
            pupilDistance: '瞳距',
            faceType: '人脸识别模式',
            faceTypeTip: '请选择识别模式',
            cycleTime: '周期上报时间',
            fastTime: '快速上报时间',
            clear: '绊线人数定时清零',
            clearTime: '清零时间',
            minWidth: '最小头肩框尺寸宽度',
            height: '高度',
            maxWidth: '最大头肩框尺寸宽度',
            warnTime: '触发时间',
            occupyTime: '车辆违停占用触发时间',
            leaveTime: '离岗触发时间',
            leavePeople: '离岗最小人数限制(1~39)',
            sleepTime: '睡岗触发时间',
            entryOccupyTime: '逃生通道阻塞触发时间',
            randoOccupyTime: '物料乱堆放触发时间',
            fallTime: '跌倒检测触发时间(3~1800)',
            phoneTime: '玩手机检测触发时间',
            flushTime: '背景刷新时间',
            reportrate: '报警间隔',
            sensitivity: '灵敏度',
            goodsMoveTime: '物品遗失触发时间',
            numLimit: '人员数量超限人数上限',
            numTime: '人员数量超限触发时间',
            loiterTime: '区域内逗留时长',
            gatherTime: '人员聚集触发时间',
            gatherLimit: '人员聚集人数限制',
            timeNumLimit: '触发时间内新增人数的上限',
            vehicleTime: '车辆停稳时间',
            modeOption1: '效果优先',
            modeOption2: '快速模式',
            modeOption3: '周期模式',
            modeOption4: '提前上报'
        },
        //工服库
        workCloth: {
            empty: '暂无数据',
            deleteLibPlacholder:
                '删除工服库会同步删除库内工服数据，是否确认删除？',
            total: '共',
            libCount: '个库',
            workClothInLib: '库内工服',
            modelingStatus: '建模状态',
            modelingStatusPlaceholder: '请选择建模状态',
            selectAllInPage: '本页全选',
            deletePlaceholder: '您确定要删除吗',
            autoImport: '自动导入',
            batchImport: '批量导入',
            exportWorkCloth: '导出工服',
            modeling: '建模中',
            modelingSuccess: '建模成功',
            modelingFailed: '建模失败',
            emptyWorkCloth: '该库暂时没有工服，赶快去添加吧！',
            strip: '条',
            addLib: '新增工服库',
            libName: '工服库名称',
            libNamePlaceholder: '请输入工服库名称',
            editLib: '编辑工服库',
            exportData: '导出数据',
            exportType: '导出类型',
            autoImportWorkCloth: '自动导入工服',
            pointSelection: '点位选择',
            pointSelectionPlaceholder: '请选择点位',
            startAnalysis: '启动分析',
            selectAll: '全选',
            identifyRate: '识别率',
            identify: '共识别',
            pictures: '张图片',
            import: '导入',
            analysisAgain: '重新分析',
            importAndAnalysis: '导入已选并重新分析',
            libNameEmpty: '工服库名称不能为空',
            libNameError:
                '请输入符合格式的名称（不能为空，且长度不能超过12，且为数字、字母或者中文）',
            addSuccess: '添加成功',
            maxLength: '最长12位',
            libNameValidError:
                '请输入符合格式的名称（不能为空，且长度不能超过100，且为数字、字母或者中文）',
            editSuccess: '修改成功',
            deleteSuccess: '删除成功',
            all: '全部',
            deleteWorkClothMsg: '请选择要删除的工服',
            lower: '较低',
            generally: '一般',
            higher: '较高',
            exportAll: '全部导出',
            exportPage: '本页导出',
            exportSelect: '选中导出',
            clothEmpty: '本页数据为空时无法导出',
            selectWorkClothEmptyPlaceholder: '请选择导出工服',
            exportSuccess: '导出成功',
            channelEmptyPlaceholder: '请选择点位',
            channelLimitPlaceholder: '请选择不多于5个点位进行操作',
            importSuccess: '导入成功',
            importPlaceholder: '请选择要导入的工服',
            manuallyImport: '手动导入工服',
            workClothPhoto: '工服照片',
            workClothPhotoInfo:
                '注：图片尺寸建议不超过1920px*1080px，图片大小不超过5M',
            workClothPhotoLimit: '图片超过5M',
            workClothPhotoError: '工服图片不能为空',
            importWorkCloth: '导入工服',
            importWorkClothInfo: '注：文件只支持zip格式',
            importWorkClothPlaceholder: '请选择导入的文件',
            importWorkClothError: '部分导入失败',
            importWorkClothLimit: '文件尺寸最多800M',
            searchPlaceholder: '请输入搜索内容',
            analysisAgainMsg: '请先启动分析',
            libidEmpty: '请先新建工服库'
        },
        carPlate: {
            blacklist: '黑名单',
            whitelist: '白名单',
            list: '黑白名单',
            carPlateLib: '车牌库',
            modelingStatus: '建模状态',
            modelingStatusPlaceholder: '请选择建模状态',
            autoImport: '自动导入',
            batchImport: '批量导入',
            emptyCarPlate: '该库暂时没有车牌，赶快去添加吧！',
            searchPlaceholder: '请输入搜索内容',
            addLib: '新增车牌库',
            libidEmpty: '请先新建车牌库',
            fileParsing: '文件解析中...',
            download: '下载模板',
            fileParsingInfo:
                '注：请先下载模板，按照模板要求导入文件，文件名称必须为：platenoTemplate.zip',
            import: '导入',
            importSuccess: '导入成功',
            fileSizeLimit: '文件尺寸最多1GB',
            fileError: '部分导入失败',
            fileNameErr: '请输入正确的批量导入文件名',
            fileUploading: '文件上传中...',
            importPlaceholder: '请选择导入的文件',
            libNamePlaceholder: '请输入车牌库名称',
            libName: '车牌库名称',
            editLib: '编辑车牌库',
            importPlate: '导入车牌',
            deleteConfirm: '您确定要删除吗',
            exportPlatePlaceholder: '请选择导出车牌',
            nameEmpty: '名称不能为空',
            libMaxLengthError:
                '请输入符合格式的名称（不能为空，且长度不能超过12，且为数字、字母或者中文）',
            deleteLibPlacholder:
                '删除车牌库会同步删除库内车牌数据，是否确认删除？',
            exportCarPlate: '导出车牌',
            empty: '暂无数据',
            plate_no: '请输入车牌号',
            exportSuccess: '导出成功',
            editPlateLib: '编辑车牌库',
            total: '共',
            strip: '条',
            id: '序号',
            no: '车牌号',
            operate: '操作',
            addStaff: '新增车牌',
            editStaff: '编辑车牌',
            addSuccess: '添加成功',
            editSuccess: '修改成功',
            deleteSuccess: '删除成功'
        },
        //人员库
        people: {
            blacklist: '黑名单',
            libNamePlaceholder: '请输入搜索内容',
            deleteInfo: '删除人员库会同步删除库内人员数据，是否确认删除？',
            empty: '暂无数据',
            total: '共',
            libCount: '个库',
            whitelist: '白名单',
            libStaff: '库内人员',
            staffNamePlaceholder: '请输入姓名',
            certificateNoPlaceholder: '请输入证件号',
            telPlaceholder: '请输入联系电话',
            emailPlaceholder: '请输入电子邮箱',
            sex: '性别',
            sexPlaceholder: '请选择性别',
            modelStatus: '建模状态',
            modelStatusPlaceholder: '请选择建模状态',
            selectAllInPage: '本页全选',
            deleteConfirm: '您确定要删除吗',
            batchImport: '批量导入',
            exportStaff: '导出人员',
            modeling: '建模中',
            modelingSuccess: '建模成功',
            modelingFailed: '建模失败',
            name: '姓名',
            certificateNo: '身份证号',
            check: '查看',
            peopleEmpty: '该库暂时没有人员，赶快去添加吧！',
            strip: '条',
            editPeopleLib: '编辑人员库',
            libName: '人员库名称',
            libNameSelectPlaceholder: '请选择人员库名称',
            libNameError: '人员库名称不能为空',
            peopleLibNamePlaceholder: '请输入人员库名称',
            addLib: '新增人员库',
            list: '黑白名单',
            listPlaceholder: '请选择黑白名单',
            exportData: '导出数据',
            exportType: '导出类型',
            nameEmpty: '名称不能为空',
            nameError:
                '请输入符合格式的名称（不能为空，且长度不能超过20，且为数字、字母或者中文）',
            peopleLibEmpty: '请先新建人员库',
            addSuccess: '添加成功',
            nameMaxLength: '最长10位',
            libMaxLengthError:
                '请输入符合格式的名称（不能为空，且长度不能超过12，且为数字、字母或者中文）',
            editSuccess: '修改成功',
            deleteSuccess: '删除成功',
            all: '全部',
            male: '男',
            female: '女',
            unknown: '未知',
            unspecified: '未说明',
            exportAll: '全部导出',
            exportPage: '本页导出',
            exportSelect: '选中导出',
            peopleIsEmpty: '本页数据为空时无法导出',
            exportPeoplePlaceholder: '请选择导出人员',
            exportSuccess: '导出成功',
            deletePlaceholder: '请选择删除的人员',
            addStaff: '新增人员',
            libType: '人员库类型',
            libTypePlaceholder: '请选择人员库类型',
            other: '其他',
            facePhoto: '人脸照片',
            facePhotoLimit:
                '注：图片尺寸建议不超过8192px*4320px，图片大小不超过5M',
            peopleName: '人员名称',
            peopleNamePlaceholder: '请输入人员名称',
            peopleSex: '人员性别',
            peopleSexPlaceholder: '请选择人员性别',
            certificateType: '证件类型',
            certificateTypePlaceholder: '请选择证件类型',
            IDNumber: '证件号码',
            IDNumberPlaceholder: '请输入证件号码',
            tel: '联系电话',
            email: '电子邮箱',
            IDCard1: '身份证',
            IDCard2: '护照',
            IDCard3: '行驶证',
            IDCard999: '其他',
            passport: '护照',
            drvingLicense: '行驶证',
            facePhotoError: '图片超过5M',
            nonCompliant1: '不符合要求',
            nonCompliant2: '邮箱不符合格式',
            nonCompliant3: '手机号格式不正确',
            nonCompliant4: '图片不能为空',
            nonCompliant5: '性别不能为空',
            nonCompliant6: '证件类型不能为空',
            checkStaff: '查看人员',
            editStaff: '编辑人员',
            importStaff: '导入人员',
            fileParsing: '文件解析中...',
            fileParsingInfo:
                '注：请先下载模板，按照模板要求导入文件，文件名称必须为：faceTemplate.zip',
            import: '导入',
            fileUploading: '文件上传中...',
            importPlaceholder: '请选择导入的文件',
            fileSizeLimit: '文件尺寸最多1GB',
            fileError: '部分导入失败',
            importSuccess: '导入成功',
            download: '下载模板',
            fileNameErr: '请输入正确的批量导入文件名',
            libidEmpty: '请先新建人员库',
            birthday: '出生年月',
            birthdayPlaceholder: '请选择出生年月'
        },
        //布控任务
        controlTask: {
            controlTask: '布控任务',
            personnelControl: '人员布控',
            vehicleControl: '车辆布控',
            deleteContent: '您确定要删除吗？',
            controlList: '布控名单',
            controListPlaceholder: '请选择布控名单',
            controListEmpty: '布控名单不能为空',
            libType1: '白名单',
            libType2: '黑名单',
            libType3: '未设置',
            controlLib: '布控库',
            controlChannel: '布控点位',
            controlChannelPlaceholder: '请选择点位',
            controlChannelEmpty: '布控点位不能为空',
            alarmThreshold: '告警阈值',
            alarmThresholdPlaceholder: '请输入告警阈值(0-100)',
            alarmThresholdEmpty: '告警阈值不能为空',
            alarmStatus: '告警阈值',
            controlling: '布控中',
            controlPause: '布控暂停',
            controlEnd: '布控结束',
            controlDate: '布控日期',
            controlDateEmpty: '布控日期不能为空',
            controlDateError: '结束时间不能早于开始时间',
            controlDateEnd: '——至——',
            controlStartDatePlaceholder: '请选择开始时间',
            controlEndDatePlaceholder: '请选择结束时间，不填则永久有效',
            forever: '永久',
            operation: '操作',
            check: '查看',
            pause: '暂停',
            start: '开启',
            controlLimt: '布控任务已满，不能继续添加',
            deleteSuccess: '删除成功',
            deleteControlInfo: '请选择要删除的布控任务',
            pauseSuccess: '暂停成功',
            startSuccess: '开启成功',
            addStaffTask: '新增人员布控',
            staffLib: '人员库',
            staffLibPlaceholder: '请选择人员库',
            staffLibEmpty: '人员库不能为空',
            controlDescription: '布控原因',
            controlDescriptionPlaceholder: '请输入布控原因',
            controlDescriptionEmpty: '布控原因不能为空',
            snapStatusEmpty: '已匹配人员是否显示抓拍不能为空',
            addControlTaskSuccess: '添加布控任务成功',
            channel: '点位',
            addSuccess: '添加完成',
            controlFaild: '布控失败',
            checkStaffTask: '查看人员布控',
            snapStatus: '已匹配人员是否显示抓拍',
            yes: '是',
            no: '否',
            editStaffTask: '编辑人员布控',
            editSuccess: '编辑成功',
            controlName: '任务名称',
            controlNamePlaceholder: '请输入任务名称',
            controlNameError: '任务名称不能为空',
            controlType: '布控类型',
            controlTypePlaceholder: '请选择布控类型',
            controlTypeError: '布控类型不能为空',
            controlLibName: '布控库名称',
            enable: '使能开关',
            controlLibType: '布控库类型',
            all: '全部',
            compareSuccess: '对比成功',
            compareFail: '对比失败',
            open: '开',
            close: '关',
            addVehicleTask: '新增车牌布控',
            vehicleLib: '车牌库',
            vehicleLibPlaceholder: '请选择车牌库',
            vehicleLibEmpty: '车牌库不能为空',
            snapVehicleEmpty: '已匹配车牌是否显示抓拍不能为空',
            checkVehicleTask: '查看车牌布控',
            vehicleStatus: '已匹配车牌是否显示抓拍',
            editVehicleTask: '编辑车牌布控'
        },
        //布控数据
        controlData: {
            controlData: '布控数据',
            channelNoEmpty: '检索点位不能为空',
            personnelControl: '人脸比对',
            vehicleControl: '车牌比对',
            channelPlacholder: '请选择点位',
            namePlaceholder: '请输入姓名',
            platenoNoPlaceholder: '请输入车牌号',
            libType: '名单类型',
            certificateNoPlaceholder: '请输入编号',
            libTypePlaceholder: '请选择布控名单',
            wbList: '黑白名单',
            wbListPlaceholder: '请选择黑白名单',
            snapStatus: '是否匹配',
            snapStatusPlaceholder: '请选择是否匹配',
            vehicleStatus: '匹配模式',
            vehiclePlaceholder: '请选择匹配模式',
            similarity: '相似度',
            compareSymbolPlaceholder: '请选择大于小于',
            similarityPlaceholder: '请输入相似度',
            selectAllInPage: '本页全选',
            deleteContent: '您确定要删除吗？',
            notMatched: '未匹配',
            matched: '匹配',
            empty: '暂无相关内容~',
            total: '共',
            strip: '条',
            searchPlaceholder: '请输入搜索内容',
            exportData: '导出数据',
            exportType: '导出类型',
            exportLimit: '注：最大导出条数为1000条',
            all: '全部',
            blacklist: '黑名单',
            whitelist: '白名单',
            exportAll: '全部导出',
            exportPage: '本页导出',
            exportSelect: '选中导出',
            exportPlaceholder: '请选择导出数据',
            exportPlaceholderVehicle: '请选择导出车辆',
            exportSuccess: '导出成功',
            deleteControlInfo: '请选择要删除的布控信息',
            deleteSuccess: '删除成功',
            check: '查看',
            channelName: '所属点位',
            timeStamp: '告警时间',
            quickEditorOption4: '未处理',
            quickEditorOption1: '正报',
            quickEditorOption2: '误报',
            quickEditorOption3: '非关注',
            gender: '性别',
            age: '年龄',
            glass: '眼镜',
            mask: '口罩',
            coatcolor: '上衣颜色：',
            umbrellacolor: '是否打伞：',
            bagstyle: '是否带包：',
            capstyle: '是否戴帽子：',
            vehicleclass: '车辆类型：',
            vehiclecolor: '车身颜色：',
            platecolor: '车牌颜色：',
            plateno: '车牌号：',
            nonmotormaincolor: '车辆主要颜色：',
            passengers: '乘车人数：',
            helmet: '是否戴头盔：',
            wearHelmet: '是否戴头盔：',
            unknown: '未知',
            compareSuccess: '对比成功',
            comparimFail: '对比失败',
            male: '男',
            female: '女',
            child: '儿童',
            juvenile: '少年',
            youth: '青年',
            middleAged: '中年',
            elderly: '老年',
            no: '否',
            wear: '佩戴'
        },
        // 行为事件
        behavioralEvent: {
            eventHappened: '事件发生',
            eventHappenedTotal: '事件发生总数',
            behavioralEventNum: '行为事件次数',
            leaderboard: '排行榜',
            noData: '暂无数据',
            eventHande: '事件处理',
            unit: '件',
            eventHandeTotal: '事件处理总数',
            behavioralEvent: '行为事件',
            time: '时间',
            eventNum: '事件次数',
            today: '今日',
            last7Days: '近7天',
            last30Days: '近30天',
            mostPedestrians: '行人次数最多',
            chooseDirection: '请选择方向',
            chooseChannel: '请选择点位',
            chooseQueryDate: '请选择查询日期',
            timeNoSame: '起止时间不能相同'
        },
        // 算法种类
        algorithmType: {
            SafetyHelmetDetect: '安全帽检测',
            SmokingDetect: '吸烟检测',
            StrenousExercise: '打架检测',
            CallingDetect: '打电话检测',
            GoodsOccupy: '逃生通道堵塞检测',
            FallingDetect: '跌倒检测',
            FireDetect: '明火检测',
            SmokeDetect: '烟雾告警'
        },
        // 人数统计
        peopleStatistics: {
            peopleStatistics: '人数统计',
            chooseChannel: '请选择点位',
            chooseStatisticType: '请选择统计类型',
            tripWiresNum: '绊线人数统计',
            regionalPeopleNum: '区域人数统计',
            today: '今日',
            last7Days: '近7天',
            last30Days: '近30天',
            chooseQueryDate: '请选择查询日期',
            timeNoSame: '起止时间不能相同',
            tripwireEntry: '绊线进入',
            tripwireAway: '绊线离开',
            regionalStatistics: '区域统计'
        },
        // 数据检索
        dataRetrieval: {
            behavioralEvent: '行为事件',
            chooseChannel: '请选择点位',
            chooseAlarmType: '请选择告警类型',
            chooseSearchType: '请选择检索类型',
            chooseProcessResult: '请选择处理结果',
            namePlaceholder: '请输入姓名',
            certificateNoPlaceholder: '请输入编号',
            chooseSearchModel: '请选择匹配模式',
            selectCurPage: '本页全选',
            importToStaff: '导入到人员库',
            importToCloth: '导入到工服库',
            bigPic: '大图',
            list: '列表',
            check: '查看',
            viewPlate: '查看车牌图',
            edit: '编辑',
            video: '视频',
            download: '下载',
            noReletedData: '暂无相关内容',
            picture: '图片',
            channel: '点位',
            alarmType: '告警类型',
            dealResult: '处理结果',
            alarmTime: '告警时间',
            operate: '操作',
            total: '共',
            pieces: '条',
            uniform: '工服库',
            chooseUniform: '请选择工服库',
            clothErr: '获取工服库失败',
            exportData: '导出数据',
            exportType: '导出类型',
            alarmPicture: '告警图片',
            alarmVideo: '告警视频',
            exportTip: '注：最大导出条数为1000条',
            dealResultEdit: '处理结果编辑',
            chooseOperateData: '请选择要操作的数据',
            chooseOperateDataError: '只能选择一条数据导入',
            chooseClothError: '请选择未穿工服检测数据',
            importSuccess: '导入成功',
            importFail: '导入失败',
            exportAll: '全部导出',
            exportCurPage: '本页导出',
            exportSelect: '选中导出',
            noExport: '不导出',
            chooseExportEvent: '请选择导出事件',
            peopleIsEmpty: '本页数据为空时无法导出',
            exportSuccess: '导出成功',
            noDeal: '未处理',
            Truepositive: '正报',
            FalsePositive: '误报',
            nonConcern: '非关注',
            editSuccess: '修改成功',
            channelEmpty: '点位不能为空',
            dealResultEmpty: '处理结果不能为空',
            alarmTypeEmpty: '告警类型不能为空',
            filterTimeEmpty: '筛选时间不能为空',
            chooseDeleteData: '请选择要删除的选项',
            deleteSuccess: '删除成功',
            downloadSuccess: '下载成功',
            warnSearch: '行为分析检索',
            takeSearch: '人脸抓拍检索',
            plateno: '车牌号：'
        },
        // 查看行为事件弹框
        checkBehavior: {
            check: '查看',
            belongingPoint: '所属点位： ',
            alarmType: '告警类型： ',
            alarmTime: '告警时间： ',
            dealResult: '处理结果：',
            numberPlate: '车牌号：',
            noDeal: '未处理',
            Truepositive: '正报',
            FalsePositive: '误报',
            nonConcern: '非关注',
            gender: '性别：',
            age: '年龄：',
            glass: '眼镜：',
            mask: '口罩：',
            coatcolor: '上衣颜色：',
            umbrellacolor: '是否打伞：',
            bagstyle: '是否带包：',
            capstyle: '是否戴帽子：',
            vehicleclass: '车辆类型：',
            vehiclecolor: '车身颜色：',
            platecolor: '车牌颜色：',
            plateno: '车牌号：',
            nonmotormaincolor: '车辆主要颜色：',
            passengers: '乘车人数：',
            helmet: '是否戴头盔：',
            wearHelmet: '是否戴头盔：',
            unknown: '未知',
            all: '全部',
            compareSuccess: '对比成功',
            comparimFail: '对比失败',
            male: '男',
            female: '女',
            child: '儿童',
            juvenile: '少年',
            youth: '青年',
            middleAged: '中年',
            elderly: '老年',
            no: '否',
            wear: '佩戴',
            black: '黑色',
            white: '白色',
            grey: '灰色',
            red: '红色',
            blue: '蓝色',
            yellow: '黄色',
            orange: '橙色',
            brown: '棕色',
            green: '绿色',
            purple: '紫色',
            cyan: '青色',
            pink: '粉色',
            silver: '银色',
            yellowGrenn: '黄绿双色',
            gradientGreen: '渐变绿色',
            umbrella: '打伞',
            bag: '带包',
            hat: '戴帽子',
            bus: '大客车',
            truck: '大货车',
            car: '小轿车',
            suv: '越野车',
            van: '面包车',
            smallTruck: '小货车',
            bussinessCar: '商务车',
            mediumBus: '中型客车',
            pickupTruck: '皮卡',
            muckTruck: '渣土车',
            specialVehicle: '特种车',
            twoRounds: '两轮',
            threeRounds: '三轮',
            onePerson: '一人',
            twoPerson: '二人',
            threePerson: '三人'
        },
        // 编辑行为事件弹框
        editBehavior: {
            edit: '编辑',
            belongingPoint: '所属点位： ',
            alarmType: '告警类型： ',
            alarmTime: '告警时间： ',
            dealResult: '处理结果：',
            noDeal: '未处理',
            Truepositive: '正报',
            FalsePositive: '误报',
            nonConcern: '非关注',
            editSuccess: '修改成功'
        },
        // 行为事件视频弹框
        checkVideo: {
            video: '视频',
            noVideo: '暂无视频',
            belongingPoint: '所属点位： ',
            alarmType: '告警类型： ',
            alarmTime: '告警时间： ',
            dealResult: '处理结果：',
            noDeal: '未处理',
            Truepositive: '正报',
            FalsePositive: '误报',
            nonConcern: '非关注'
        },
        // 数据对接
        dataDock: {
            dataDock: '数据对接',
            uploadIntervalConfig: '上传时间间隔配置',
            channelName: '点位信息',
            uploadInterval: '上传间隔时间（min）',
            openReport: '间隔使能',
            audioEnable: '音频使能',
            enable: '音柱使能',
            platformConfig: '平台配置',
            HttpModel: 'HTTP/HTTPS模式',
            liveModel: 'HTTP/HTTPS保活',
            isOpen: '是否开启',
            open: '开启',
            close: '关闭',
            pushAddress: '推送地址',
            netDetect: '网络检测',
            liveTime: '保活时间',
            uploadPicData: '上传图片数据',
            wsModel: 'WebSocket模式',
            saveSuccess: '保存成功',
            audioTime: '告警音频播放次数',
            MQTT: 'MQTT配置',
            MQTTModel: 'MQTT模式',
            audio: '音柱配置',
            report: '多目标上报方式',
            channelinfo: '上传通道状态',
            video: '上传视频数据',
            pubswitch: 'AP100/AP1000公网开关',
            operswitch: '运维平台开关',
            priswitch: 'AP100/AP1000私网开关',
            audioIp: '音柱IP',
            audioPlayTime: '音柱播放次数',
            audioVol: '音柱音量',
            eth: '设备网卡',
            state: '音柱状态',
            noLiveTime: '保活时间为空！',
            noIntervalTime: '上传时间间隔不能为空',
            pushVideoAddress: '视频推送地址',
            plzAudioIp: '请输入音柱IP',
            test: '上报测试',
            face: '人脸',
            ba: '行为',
            selectType: '选择上报类型',
            videoInfo: '告警视频',
            ssForm: '访问策略配置'
        },
        // 数据看板
        dataPanel: {
            oneScreens: '切为单屏',
            fourScreens: '切为四屏',
            peopleCount: '人数统计',
            areaPeople: '区域人数',
            enterPeopleCount: '绊线进入人数',
            leavePeopleCount: '绊线离开人数',
            fullScreen: '全屏播放',
            replaceVideo: '切换点位',
            alarmInfo: '报警信息',
            mismatch: '不匹配',
            similarity: '相似度：',
            allVideo: '所有点位',
            clearPeople: '绊线人数清零',
            useMse: '硬解码',
            channel: '通道',
            alarmType1: '未穿工服检测',
            alarmType2: '未穿工服检测'
        },
        // 用户配置
        userConfig: {
            title: '用户配置',
            setPwdProtect: '设置密保',
            user: '用户名',
            role: '角色',
            operate: '操作',
            resetPwd: '重置密码',
            validatePwd: '校验密码',
            originPwd: '原始密码',
            originPwdPlaceholder: '请输入原始密码',
            pwd: '密码',
            confirmPwd: '确认密码',
            pwdPlaceholder: '请输入密码',
            validateSuccess: '校验成功',
            validateFail: '校验失败',
            userNameNotEmpty: '姓名不能为空',
            roleNotEmpty: '角色不能为空',
            addUser: '新增用户',
            question_1: '问题1',
            question_2: '问题2',
            question_3: '问题3',
            answer_1: '答案1',
            answerPlaceholder: '问题不能为空',
            answer_2: '答案2',
            answer_3: '答案3',
            settingSuccess: '设置成功',
            validNameError:
                '请输入符合格式的名称（20个字符以内，且为数字、字母或者中文）',
            validPwdError:
                '请输入符合格式的密码（6~20个字符以内,不能包含中文与空格）',
            originPwdError: '原始密码输入错误',
            questionNull: '请先设置密保问题',
            operation: '操作员',
            admin: '管理员',
            editSuccess: '修改成功,请重新登录',
            passwordEmpty: '密码不能为空',
            eidtUser: '编辑用户'
        },
        // 角色配置
        roleConfig: {
            roleSetting: '角色配置',
            roleName: '角色名称',
            rolePermission: '角色权限',
            permission: '权限',
            addRole: '新增角色',
            editRole: '编辑角色'
        },
        // 维护
        defenderConfig: {
            updateVersion: '版本升级',
            fileTip: '文件解析中...',
            update: '升级',
            completeUpdate: '完全升级',
            deviceReset: '设备还原',
            saveUserConfig: '保留用户配置',
            reset: '还原',
            deviceReboot: '设备重启',
            resetTip: '请耐心等待...',
            rebootTip: '重启需要2-5分钟，请耐心等待...',
            reboot: '重启',
            settingsInfo: '配置信息',
            settingTip: '配置导入会删除当前点位数据，请谨慎操作',
            import: '导入',
            export: '导出',
            testInfo: '诊断信息',
            exportTip: '导出系统诊断日志耗时较长，请耐心等待...',
            timeReboot: '定时重启',
            coverSetting: '满覆盖配置',
            stay: '保留',
            dayData: '天数据',
            wanData: '万数据',
            waitReboot: '正在重启，请稍后...',
            choseFileTip: '请选择导入的文件',
            choseFileTip1: '请关闭远程升级使能开关',
            fileUploadSuccess: '文件上传成功',
            uploadFailed: '升级失败',
            sending: '传输中',
            uploading: '升级中',
            uploadSuccess: '升级成功',
            uploadStatus: '正在升级',
            deviceResetSuccess: '设备还原成功',
            deviceResetFail: '设备还原失败',
            rebootSuccess: '重启成功',
            rebootFail: '重启失败',
            partImportFailed: '部分导入失败',
            importSuccess: '导入成功',
            exportSuccess: '导出成功',
            choseFile: '请选择要导入的文件',
            selectTimeTip: '请选择时间',
            timeToStartSet: '定时重启设置成功',
            saveSuccess: '保存成功',
            remoteUpgrade: '远程升级',
            reLogin1: '升级成功，请稍后登录',
            reLogin2: '升级成功，IP地址已修改，请重新登录',
            ipErr: '请先输入IP',
            portErr: '请先输入端口号',
            rebootTipMsg: '你确定要重启吗？',
            resetTipMsg: '你确定要还原吗？',
            net: '网络配置',
            save: '保留',
            noSave: '不保留'
        },
        // 版本信息
        versionInfo: {
            versionInfo: '版本信息',
            softVersion: '软件版本',
            macAddress: 'MAC地址',
            runtime: '运行时间',
            name: '设备名称',
            namePlaceholder: '请输入设备名称',
            codePlacehodler: '请输入设备编码',
            code: '设备编码',
            lan: '设备语言',
            model: '产品型号',
            serialnumber: '序列号',
            firmwareversion: '软件版本',
            nameNull: '设备名称不能为空',
            codeNull: '设备编码不能为空',
            nullError: '请不要输入空格或者空名称',
            lengthError: '请输入符合格式的名称（32个字符以内）',
            successMsg: '修改数据成功'
        },
        networkConfig: {
            networkConfig: 'TCP/IP',
            baseInfo: '基本信息',
            NIC: '默认网卡',
            plzNIC: '请选择网卡',
            ipGet: '获取IP地址',
            ip: 'IP地址',
            ipType: 'IP类型',
            noIP: 'IP地址不能为空',
            mask: '子网掩码',
            noMask: 'IP子网掩码不能为空',
            getaway: '默认网关',
            noGetaway: 'IP默认网关不能为空',
            addressSet: '地址设置',
            static: '静态',
            auto: '动态',
            dns: 'DNS服务器',
            dns1: '自动获取DNS服务器',
            dns2: '使用以下DNS服务器',
            route: '默认路由',
            switch: '使能开关',
            plzRoute: '请选择默认路由',
            nps: 'NPS配置',
            serve: '服务端地址',
            port: '服务端端口',
            key: '密钥',
            name: '名称',
            nicType: '网卡类型：',
            NIC1: '网卡1',
            NIC2: '网卡2',
            noServer: '服务端地址不能为空！',
            noPort: '服务端端口不能为空！',
            error: '请提交符合格式要求的内容',
            editSuc: '修改成功',
            netSet: '使用此网络接口设置：',
            ipError: '服务端地址有误',
            portError: '端口有误',
            noKey: '密钥不能为空！',
            noIp: '请不要输入空IP'
        },
        // 操作日志
        operationLog: {
            log: '操作日志',
            selectModeTip: '请选择模块',
            selectSatusTip: '请选择状态',
            time: '时间',
            operationUser: '操作者',
            operationType: '类别',
            remark: '备注',
            status: '状态',
            success: '成功',
            failed: '失败',
            all: '全部',
            userManage: '用户管理',
            roleManage: '角色管理',
            sysManage: '系统管理',
            algorithmManage: '算子管理',
            dataPlatform: '数据对接',
            smartRule: '智能分析任务配置',
            controlTime: '布防时间',
            logoConfig: 'logo配置',
            personControl: '人员布防',
            workCloth: '工服库',
            pkMode: '扩展配置',
            selectDateTip: '请选择查询日期',
            timeNoSame: '起止时间不能相同'
        },
        // 算子管理
        algorithmConfig: {
            cpuPercent: 'CPU占用：',
            ramPercent: '内存占用：',
            noData: '暂无数据',
            usedPercent: '已用',
            noneUse: '剩余',
            chartsTarget: '指标',
            selectAlgo: '请选择一个算子',
            addAlgorithm: '新增算子',
            algoName: '算子名称',
            IPAddress: 'IP地址',
            port: '端口',
            algorithmNameTip: '算子名称不能为空',
            algoLengthTip: '算子名称最多15位',
            ipNoEmpty: 'IP不能为空',
            ipNoSuite: '请输入符合格式的IP地址',
            portNoEmpty: '端口不能为空',
            portNoSuite: '请输入符合格式的端口号',
            editalgorithm: '编辑算子'
        },
        // 时间配置
        timeConfig: {
            timeSettings: '时间配置',
            currentDate: '当前时间',
            setTime: '设置时间',
            handSetTime: '手动校时',
            dataAndTime: '日期/时间',
            dataAndTimeTip: '日期/时间不能为空',
            setNTPServer: '设为NTP服务',
            syncTime: '同步计算机时间',
            NTPsetTime: 'NTP校时',
            serverAddress: '服务器地址',
            networkTest: '网络检测',
            setTimeOut: '校时时间间隔',
            serverTimeTip: '服务器校时时间间隔不能为空',
            minTip: '分钟',
            serverAddressTip: '请输入服务器地址',
            addressTip: '地址不能为空',
            addressNoSuit: '地址不符合规范',
            port: 'NTP端口',
            portTip: 'NTP端口不能为空',
            enable: '使能开关',
            yesterday: '昨天',
            week: '一周前',
            now: '此刻'
        },
        // 个性化配置
        customConfig: {
            customTitle: '个性化配置',
            logoAndName: 'Logo及名称',
            systemTip1: '系统标识(小)',
            systemTip2: '系统标识',
            resetDefaultStyle: '恢复默认样式',
            imgTip1: '图片分辨率建议为40*40或者40的倍数*40的倍数',
            imgTip2: '图片分辨率建议为160*60或者160的倍数*60的倍数',
            sysName1: '页面标题',
            sysName2: '登录标题',
            defaultName: '默认名称',
            customName: '自定义名称',
            customNameTip: '请输入自定义名称',
            defaultNameTip: '默认名称为',
            AOXName: '天璇后台管理系统',
            aoxTip: '，可自定义名称，支持中英文混合（不得超过16个汉字或字母）',
            functionSetting: '功能文案设置',
            functionTip:
                '注：默认显示系统所有功能，如将某一功能关闭则在点位管理中也不显示此功能！',
            functionDefault: '功能默认名称',
            showOrNot: '是否显示',
            resetDefault: '恢复默认',
            imgError: '图片超过2M',
            logoTip: 'logo图片只能是jpg、jpeg或png格式',
            sysNameError: '系统名称不能为空',
            sysNameLengthTip: '系统名称不得超过16位',
            sysNameStyleTip: '系统名称不符合规范，只能为汉字或者字母',
            customNameLength: '自定义名称最多为15位',
            customNameEmpty: '自定义名称不能为空',
            noClose: '已配置分析任务，无法关闭',
            audio: '音频文件',
            videoUpload: '音频上传',
            noImg: '图片为空',
            choseFileTip: '请选择导入的文件',
            logoTip1: '图片分辨率建议为160*60或者160的倍数*60的倍数',
            alarmtype: '告警类型'
        },
        // 隐藏界面的数据上报
        dataSend: {
            title: '行为事件',
            chooseChannel: '请选择点位',
            chooseWarnType: '请选择告警类型',
            chooseResult: '请选择处理结果',
            choosePageAll: '本页全选',
            ifOpen: '是否开启',
            send: '发送',
            pic: '大图',
            list: '列表',
            noContent: '暂无相关内容',
            picture: '图片',
            channel: '点位',
            warnType: '告警类型',
            result: '处理结果',
            warnTime: '告警时间',
            operation: '操作',
            total: '共',
            size: '条',
            importWork: '导入到工服库',
            workCloth: '工服库',
            workClothTip: '请选择工服库',
            export: '导出数据',
            exportType: '导出类型',
            exportTip: '注：最大导出条数为1000条',
            resultEdit: '处理结果编辑',
            saveSuccess: '保存成功',
            chooseData: '请选择要操作的数据',
            sendSuccess: '发送成功',
            importSuccess: '导入成功',
            export1: '全部导出',
            export2: '本页导出',
            export3: '选中导出',
            chooseEvent: '请选择导出事件',
            exportSuccess: '导出成功',
            type1: '正报',
            type2: '误报',
            type3: '非关注',
            editSuccess: '修改成功',
            type4: '未处理',
            searchErr1: '点位不能为空',
            searchErr2: '处理结果不能为空',
            searchErr3: '告警类型不能为空',
            searchErr4: '筛选时间不能为空',
            deleteErr1: '请选择要删除的选项',
            deleteSuccess: '删除成功',
            downloadSuccess: '下载成功',
            watch: '查看',
            belongChannel: '所属点位',
            plate: '车牌号'
        },
        // 隐藏界面扩展配置
        pk: {
            title: '扩展配置',
            sensitive: '灵敏度',
            interval: '上报间隔',
            direction: '触发方向',
            peopleNum: '告警人数',
            background: '背景刷新时长',
            saveSuccess: '保存成功',
            second: '秒',
            people: '人'
        },

        // 配置界面
        // 系统维护

        // 网络抓包
        networkGrab: {
            title: '网络抓包',
            networkChoose: '网卡选择',
            networkPlaceholder: '请选择网卡',
            size: '数据包大小（字节）',
            ip: 'IP地址',
            hostType1: '全部',
            hostType2: '指定',
            hostType3: '过滤',
            port: '端口',
            ipErr1: 'IP地址有误',
            ipErr2: '请不要输入空IP',
            portErr1: '端口有误',
            portErr2: '请不要输入空端口',
            sizeErr: '包不能为空',
            success: '操作成功',
            warn: '导出完成前请勿开始抓包',
            stopGrab: '停止抓包',
            startGrab: '开始抓包'
        },
        // 安全中心
        security: {
            title: '安全中心',
            modifyPwd: 'ROOT密码修改',
            fireConfig: '防火墙配置修改',
            oldPwd: '旧密码',
            newPwd: '新密码',
            pwdEmpty: '密码不能为空',
            ip: '指定IP',
            net: '指定网段',
            restore: '恢复默认设置',
            pwdErr: '请输入符合格式的密码（6-40个字符以内,不能包含中文与空格）',
            ipErr: 'IP格式有误',
            netErr: '请输入网段',
            fireEmpty: '配置不能为空'
        },
        // 资源占用列表
        resourceList: {
            title: '资源占用列表',
            number: '通道号',
            name: '通道名称',
            task: '当前任务'
        },
        // 基础配置
        basicConfig: {
            title: '基础配置',
            version: '算法版本'
        },
        serviceList: {
            title: '服务列表',
            name: '服务名',
            status: '状态',
            online: '在线',
            offline: '离线',
            pid: '进程',
            version: '版本',
            time: '启动时间',
            operate: '操作',
            getLog: '获取日志',
            log: '日志信息',
            moreInfo: '详细信息',
            getInfo: '获取详细信息'
        },
        apiStatus: {
            title: '接口状态',
            name: '服务名',
            url: '接口名',
            cnt: '调用总次数',
            day: '日期',
            cnt1: '100ms内的调用次数',
            cnt2: '200ms内的调用次数',
            cnt3: '300ms内的调用次数',
            cnt4: '400ms内的调用次数',
            cnt5: '500ms内的调用次数',
            cnt6: '1000ms内的调用次数',
            cnt7: '1000ms以上的调用次数'
        },
        algType: {
            facedetection: '人脸布控',
            safehatdetection: '未戴安全帽检测',
            workclothesdetection: '未穿工服检测',
            callingdetection: '打电话检测',
            smokingdetection: '吸烟检测',
            smokefiredetection: '烟火检测',
            sleepdutydetection: '睡岗检测',
            absentdetection: '离岗检测',
            passagewayoccupy: '逃生通道堵塞',
            goodsmoveddetetion: '物品遗失',
            regionalinvasion: '区域入侵',
            elcforbid: '电动车禁入',
            firetruckoccupy: '车辆违停占用',
            falldetection: '跌倒检测',
            pedestriananalysisregion: '区域人数统计',
            areapersabndetection: '人员数量超限',
            pedestriancountingline: '绊线人数统计',
            reflectclothesdetection: '未穿反光服检测',
            quicklymoving: '快速移动',
            maskdetection: '未戴口罩检测',
            climbingdetect: '攀高检测',
            loiteringdetect: '逗留检测',
            persongathered: '人员聚集',
            strenousexercise: '打架检测',
            firedetect: '明火告警',
            fumesdetection: '烟雾告警',
            playphonedetect: '玩手机检测',
            cookhatdetect: '未戴厨师帽检测',
            mousedetect: '鼠患检测',
            chefclothesdetect: '未穿厨师服检测',
            shirtlessdetect: '赤膊检测',
            trashcanfulldetect: '未盖垃圾桶检测',
            coverlanddetect: '裸土未覆盖',
            safetybeltdetect: '未穿安全背带',
            vehicledriveway: '车辆未清洗',
            face: '人脸业务配置',
            fireemergency: '消防应急类配置',
            safeproduction: '安全生产类配置',
            chefbrightstove: '明厨亮灶类配置',
            vas: '机非人配置',
            personCapture: '人脸检测',
            noWearWorkCloth: '未穿工服检测',
            personMatched: '人脸匹配',
            personNotRegistered: '人脸未注册',
            randomMaterials: '物料乱堆放',
            knifestickdetect: '手持刀棍检测',
            allstructurevideovnpf: '全结构化检测',
            persondetection: '人体检测',
            vehicledetection: '机动车检测',
            vehicledetection1: '车牌匹配成功',
            vehicledetection2: '车牌匹配失败',
            nonvehicledetection: '非机动车检测'
        },
        alarmType: {
            noWearWorkCloth: '未穿工服检测',
            personCapture: '人脸检测',
            personMatched: '人脸匹配',
            personNotRegistered: '人脸未注册',
            randomMaterials: '物料乱堆放'
        }
    },
    statusCode: {
        200: '操作成功',
        400: '请求无效',
        401: '权限不足',
        403: '禁止访问',
        404: '请求不存在',
        500: '无法连接到服务器',
        1000: '操作失败',
        1001: '无文件',
        1002: '文件后缀不支持',
        1003: '正在升级中',
        1004: '正在压缩日志中',
        1005: '传入路径错误，请确认',
        1006: '升级包不存在，请确认',
        1007: '升级名错误，请确认',
        1303: '压缩包文件名错误',
        1304: '无配置文件或xml文件名错误',
        1305: '版本错误，请选择正确的版本上传',
        1306: '配置文件导入失败',
        1307: 'ROOT原密码校验失败',
        1500: '该用户不可删除',
        1501: '该用户名不能添加',
        1502: '该用户名不能修改角色',
        1503: '用户名超出长度限制',
        1504: '用户名含有特殊字符，请修改',
        1505: '用户名已存在，请修改',
        1506: '用户名或密码错误',
        1507: '密码长度必须必须大于6',
        1508: '密保校验失败',
        1509: '未设置密保问题',
        1510: '用户名不存在，请确认',
        1600: '通道名称超出长度限制',
        1701: '连接超时',
        1702: '鉴权失败',
        1703: 'IP地址校验错误',
        1704: '音频音量超出范围限制',
        1705: '音频播放次数超出范围限制',
        1706: '网卡配置错误',
        1707: '使能开关配置错误',
        1708: '通道id类型错误',
        1800: 'param check failed',
        1900: 'IP地址和默认网关不在同一网段内，请修改',
        1901: '子网掩码不合法，请修改',
        1902: '设备名称超出长度限制',
        1903: '两网卡IP地址相同，请修改',
        1904: '网络配置未改变，请确认',
        2000: '算法未初始化',
        2001: '启动任务失败',
        2002: '停止任务失败',
        2003: '算法切换失败',
        2004: '获取通道信息失败',
        2005: '开启通道数达到最大值',
        2006: '灵敏度参数有误',
        2007: '上报间隔参数有误',
        2008: '离岗触发时长参数有误',
        2009: '刷新时长有误',
        2010: '区域入侵告警方向参数有误',
        2011: '人脸最大目标尺寸有误',
        2012: '睡岗触发时长参数有误',
        2013: '告警人数阈值有误',
        2014: '人脸最小目标尺寸有误',
        2015: '算法未销毁，无法初始化',
        2016: '算法未初始化，无法销毁',
        2017: '算法初始化失败',
        2018: '算法销毁失败',
        2019: '当前算法模式不支持',
        2031: '访问智能数据库有误',
        2032: '数量未达100000，无需删除',
        2033: '"alarm/ba"下无文件夹',
        2034: '无可以下发的特征值',
        2035: '区域个数超出最大值',
        2036: '任务不支持',
        2037: '区域坐标值超出范围[0-10000]',
        2038: '区域点个数超出最大值',
        2039: '规则框类型有误',
        2040: '缺少参数',
        2041: '区域坐标顺时针',
        2042: '区域坐标逆时针',
        2043: '当前任务已存在',
        2044: '下发的任务规则数为空',
        2045: '当前任务无法开启',
        2046: '不支持的算法模式',
        2047: '访问人员数据库错误',
        2048: '库已存在',
        2049: '默认列表不可删除',
        2051: '当前算法有任务开启，无法切换',
        2052: '算法初始化中，请稍等',
        2053: '工服库数量超出最大值',
        2054: '工服数量超出最大值',
        2055: '库已存在',
        2056: '名称相同请重新填写',
        2057: '工服库不存在，请确认',
        2058: '工服ID不存在，请确认',
        2059: '请选择照片',
        2060: '已达到最大库数量',
        2061: '已达到最大人员数量',
        2062: '库不存在',
        2063: '得到图片特征值有误',
        2064: '源特征值长度有误',
        2065: '目标特征值长度有误',
        2066: '请初始化算法',
        2067: '算法释放中',
        2068: '向算法下发特征值成功但修改数据库失败',
        2069: '向算法修改特征值成功但修改数据库失败',
        2070: '写入人员照片失败',
        2071: '数据库人员表操作失败',
        2072: '导库进行中，请稍后再试',
        2073: '超过单次导库最大数目',
        2074: '正在建模，请等待',
        2075: '剩余库容不足以容纳当前导入数量',
        2076: '算法切换中，请稍后再试',
        2077: '参数长度超过最大值',
        2078: '该face_id不存在',
        2079: '当前算法模式非安全生产类算法，请切换算法模式',
        2080: '部署算法中，请稍后再试',
        2081: '工服抓拍智能分析中，无法开启工服检测',
        20821: '所选择图片张数超出工服库容量限制(剩余容量为',
        20822: '张)，请重新选择',
        2083: '参数错误，请确认',
        2084: '参数不在范围内，请确认',
        2085: '下发参数不存在，请确认',
        2086: '上报类型参数错误，请确认',
        2087: '导出数据为空，请确认',
        2088: '已达到最大车牌数量',
        2089: '车牌号长度不合规',
        2090: '数据库操作车牌信息失败',
        2091: '该车牌id不存在',
        2092: '库内已有此车牌号，请重新写入',
        2093: '任务列表为空',
        2094: '任务配置中,请稍后重试',
        2095: '车辆违停触发时长参数有误',
        2096: '物品遗失触发时长参数有误',
        2097: '智能任务存在互斥，无法开启',
        2098: '尺寸参数有误',
        2099: '区域入侵触发参数有误',
        2200: 'MQTT模式输入格式有误，请输入IP地址和端口号',
        2201: 'MQTT模式开启下禁止操作',
        2202: 'MQTT使能开启后，至少开启一种MQTT模式',
        2203: 'AP100/AP1000平台仅支持开启一种模式',
        2204: 'QTT使能开启后，最多开启一种MQTT模式',

        2100: '通道堵塞触发参数有误',
        2101: '跌倒触发参数有误',
        2102: '人数异常触发时间参数有误',
        2103: '人数上限参数有误',
        2104: '绊线清零参数错误',
        2105: '告警保存时长参数有误',
        2106: '算力已超出极限值',
        2107: '任务下发中，请稍后重试',
        2108: '工服库特征为空',
        2109: '存在非图片文件',
        2110: '图片尺寸超出1920x1080',
        2111: '玩手机触发时长参数有误',
        2112: '未盖垃圾桶触发时长参数有误',
        2113: '车辆停稳时长参数有误',
        2114: '静止超时触发时长参数有误',
        2301: '库内没有可导出数据',
        2302: '车牌号不合规',
        2303: '模板错误，导库失败',
        2304: '人员图片不能为空',
        2305: '数据异常，操作失败',
        2306: '智能任务正使用此工服库，请取消任务后进行删除操作',
        2307: '建模状态不存在'
    }
};
