<template>
    <bre-modal :visible="resetPwdVisible" class="reset-pwd" :title="t('content.modifyPwd.title')" @cancel="cancel">
        <div class="main">
            <bre-form ref="formRef" :model="form" label-align="left" auto-label-width>
                <bre-form-item field="pre_password" show-colon :label="t('content.modifyPwd.oldPassword')" :rules="[
                    {
                        required: true,
                        message: t('content.modifyPwd.oldPasswordPlaceholder')
                    }
                ]" validate-trigger="input">
                    <bre-input-password v-model="form.pre_password" :placeholder="t('content.modifyPwd.oldPasswordPlaceholder')" />
                </bre-form-item>
                <bre-form-item field="new_password" show-colon :label="t('content.modifyPwd.newPassword')"
                    :rules="[{ required: true, validator: validatePassword }]" :validate-trigger="['change','input']">
                    <bre-input-password v-model="form.new_password" @input="inputValidate"
                        :placeholder="t('content.modifyPwd.newPasswordPlaceholder')" />
                </bre-form-item>
                <bre-form-item show-colon :label="t('content.modifyPwd.pwdStrength')">
                    <bre-progress v-if="
                        pwdStrength ===
                        $t('content.modifyPwd.pwdStrengthFourth')
                    " :percent="1" color="#00FF00" :animation="true">
                        <template>
                            {{t('content.modifyPwd.pwdStrengthFourth')}}
                        </template>
                    </bre-progress>
                    <bre-progress v-if="
                        pwdStrength === $t('content.modifyPwd.pwdStrengthThird')
                    " :percent="0.66" color="#FF8C00" :animation="true">
                        <template>
                            {{t('content.modifyPwd.pwdStrengthThird')}}
                        </template>
                    </bre-progress>
                    <bre-progress v-if="
                        pwdStrength ===
                        $t('content.modifyPwd.pwdStrengthSecond')
                    " :percent="0.33" color="#FF0000" :animation="true">
                        <template>
                            {{t('content.modifyPwd.pwdStrengthSecond')}}
                        </template>
                    </bre-progress>
                    <bre-progress v-if="
                        pwdStrength === $t('content.modifyPwd.pwdStrengthFirst')
                    " :percent="0" :animation="true">
                        <template>
                            {{t('content.modifyPwd.pwdStrengthFirst')}}
                        </template>
                    </bre-progress>
                </bre-form-item>
                <bre-form-item field="confirm_new_password" show-colon :label="t('content.modifyPwd.confirmNewPassword')"
                    :rules="[{ required: true, validator: validateNewPwd }]" :validate-trigger="['change','input']">
                    <bre-input-password v-model="form.confirm_new_password" :placeholder="
                        t('content.modifyPwd.confirmNewPasswordPlaceholder')
                    " />
                </bre-form-item>
            </bre-form>
        </div>
        <template #footer>
            <bre-button type="primary" class="ok" @click="submit">{{t('content.button.confirm')}}</bre-button>
            <bre-button class="cancel" type="outline" @click="cancel">
                {{t('content.button.cancel')}}
            </bre-button>
        </template>
    </bre-modal>
</template>
<script lang="ts" setup>
import { ref, reactive, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';
import md5 from 'js-md5';
const { t } = useI18n();
// const props = defineProps({
//     resetPwdVisible: Boolean
// });
const emit = defineEmits(['close', 'submit']);
const form = reactive({
    pre_password: '',
    new_password: '',
    confirm_new_password: ''
});
const pwdStrength = ref('无');
const formRef = ref();
const inputValidate = () => {
    if (form.confirm_new_password !== '') {
        formRef.value.validateField('confirm_new_password');
    }
};
const submit = () => {
    formRef.value.validate((invalid: any) => {
        if (!invalid) {
            const editForm = {
                pre_password: md5(form.pre_password),
                new_password1: md5(form.new_password),
                new_password2: md5(form.confirm_new_password)
            };
            emit('submit', editForm);
            // cancel();
        }
    });
};
const cancel = () => {
    emit('close');
    formRef.value.clearValidate();
    formRef.value.resetFields();
};
const validatePassword = (value: string, callback: any) => {
    // else if (value.match(/([\u4E00-\u9FA5])+/)) {
    //     callback(t('content.modifyPwd.pwdChinese'));
    // }
    // else if (value.indexOf(' ') > -1) {
    //     callback(t('content.modifyPwd.pwdSpace'));
    // }
    if (!value || value.length === 0) {
        pwdStrength.value = t('content.modifyPwd.pwdStrengthFirst');
        callback(t('content.modifyPwd.pwdNull'));
    } else if (value.length < 6 || value.length > 20) {
        callback(t('content.modifyPwd.pwd620'));
    } else {
        let ls = 0;
        if (form.new_password.match(/([a-z])+/)) {
            ls++;
        }
        if (form.new_password.match(/([0-9])+/)) {
            ls++;
        }
        if (form.new_password.match(/([A-Z])+/)) {
            ls++;
        }
        if (form.new_password.match(/([\W])+/)) {
            ls++;
        }
        /* eslint-disable */
        switch (ls) {
            case 0:
                pwdStrength.value = t('content.modifyPwd.pwdStrengthFirst');
                break;
            case 1:
                pwdStrength.value = t('content.modifyPwd.pwdStrengthSecond');
                break;
            case 2:
                pwdStrength.value = t('content.modifyPwd.pwdStrengthThird');
                break;
            case 3:
                pwdStrength.value = t('content.modifyPwd.pwdStrengthThird');
                break;
            case 4:
                pwdStrength.value = t('content.modifyPwd.pwdStrengthFourth');
                break;
        }
        /* eslint-enable */
        callback();
    }
};

const validateNewPwd = (value: any, callback: Function) => {
    if (!value) {
        callback(t('content.modifyPwd.confirmPwdEmpty'));
    } else if (value !== form.new_password) {
        callback(t('content.modifyPwd.confirmPwdSame'));
    }
};
</script>
<style lang="scss">
.reset-pwd {
    .arco-modal {
        width: 900px;
        .arco-form-item-content-wrapper {
            width: 360px;
        }
        .arco-modal-body {
            display: flex;
            justify-content: center;
            margin-top: 10px;
        }
    }
}
</style>
