import { defineStore } from 'pinia';
export const useTagsViewStore = defineStore({
    id: 'tasgView', // id必填，且需要唯一
    state: () => ({
        visitedViews: [],
        cachedViews: []
    }),
    actions: {
        addView(view) {
            addVisitedView(view);
            addCachedView(view);
        },
        addVisitedView(view) {
            if (this.visitedViews.some((v) => v.path === view.path)) {
                return;
            }
            this.visitedViews.push(
                Object.assign({}, view, {
                    title: view.meta.title || 'no-name'
                })
            );
        },
        addCachedView(view) {
            if (this.cachedViews.includes(view.name)) {
                return;
            }
            if (!view.meta.noCache) {
                this.cachedViews.push(view.name);
            }
        },
        delCurrentView(_this) {
            const view = _this.$route;
            for (const v of this.visitedViews) {
                if (v.path === view.path) {
                    return new Promise((resolve) => {
                        this.delVisitedView(v);
                        this.delCachedView(v);
                        resolve();
                    });
                }
            }
        },
        delView(view) {
            return new Promise((resolve) => {
                this.delVisitedView(view);
                this.delCachedView(view);
                resolve({
                    visitedViews: [...this.visitedViews],
                    cachedViews: [...this.cachedViews]
                });
            });
        },
        delVisitedView(view) {
            return new Promise((resolve) => {
                for (const [i, v] of this.visitedViews.entries()) {
                    if (v.path === view.path) {
                        this.visitedViews.splice(i, 1);
                        break;
                    }
                }
                resolve([...this.visitedViews]);
            });
        },
        delCachedView(view) {
            return new Promise((resolve) => {
                for (const i of this.cachedViews) {
                    if (i === view.name) {
                        const index = this.cachedViews.indexOf(i);
                        this.cachedViews.splice(index, 1);
                        break;
                    }
                }
                resolve([...this.cachedViews]);
            });
        },

        delOthersViews({}, view) {
            return new Promise((resolve) => {
                this.delOthersVisitedViews(view);
                this.delOthersCachedViews(view);
                resolve({
                    visitedViews: [...this.visitedViews],
                    cachedViews: [...this.cachedViews]
                });
            });
        },
        delOthersVisitedViews({}, view) {
            return new Promise((resolve) => {
                this.visitedViews = this.visitedViews.filter(
                    (v) => v.meta.affix || v.path === view.path
                );
                resolve([...this.visitedViews]);
            });
        },
        delOthersCachedViews({}, view) {
            return new Promise((resolve) => {
                for (const i of this.cachedViews) {
                    if (i === view.name) {
                        const index = this.cachedViews.indexOf(i);
                        this.cachedViews = this.cachedViews.slice(
                            index,
                            index + 1
                        );
                        break;
                    }
                }
                resolve([...this.cachedViews]);
            });
        },

        delAllViews({}, view) {
            return new Promise((resolve) => {
                this.delAllVisitedViews(view);
                this.delAllCachedViews(view);
                resolve({
                    visitedViews: [...this.visitedViews],
                    cachedViews: [...this.cachedViews]
                });
            });
        },
        delAllVisitedViews({}) {
            return new Promise((resolve) => {
                const affixTags = this.visitedViews.filter(
                    (tag) => tag.meta.affix
                );
                this.visitedViews = affixTags;
                resolve([...this.visitedViews]);
            });
        },
        delAllCachedViews({}) {
            return new Promise((resolve) => {
                this.cachedViews = [];
                resolve([...this.cachedViews]);
            });
        },
        updateVisitedView({}, view) {
            for (let v of this.visitedViews) {
                if (v.path === view.path) {
                    v = Object.assign(v, view);
                    this.currentView = v;
                    break;
                }
            }
        }
    }
});
