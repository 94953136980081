<template>
    <div id="app">
        <ConfigProvider componentConfig={componentConfig}>
            <router-view v-if="isRouterAlive"></router-view>
        </ConfigProvider>
    </div>
</template>
<script lang="ts" setup>
import { onMounted, ref, nextTick, provide } from 'vue';
import loginApi from '@/api/login';
import to from 'await-to-js';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
import { useAppStore } from '@/store/app';
import { Message } from '@bresee-ui/web-vue';
import Cookies from 'js-cookie';

const useApp = useAppStore();
const router = useRouter();
onMounted(() => {
    const theme = localStorage.getItem('theme') || 'default';
    document.body.setAttribute('arco-theme', theme);
    if (theme === 'default') {
        document.body.style.setProperty('--primary-6', '51, 153, 255');
        document.body.style.setProperty('--primary-5', '80, 168, 255');
        document.body.style.setProperty('--primary-7', '67, 129, 222');
        document.body.style.setProperty('--primary-3', '168, 211, 255');
    }
    useApp.setTheme(theme);
    Cookies.set('language', 'zh');
    const token = sessionStorage.getItem('token');
    if (token) {
        useApp.getWarnList();
    }
    window.addEventListener('setItem', () => {
        const token = sessionStorage.getItem('token');
        if (token) {
            validateLicense();
        }
    });
});
const isRouterAlive = ref(true);
const reload = () => {
    isRouterAlive.value = false;
    nextTick(() => {
        isRouterAlive.value = true;
    });
};
provide('reload', { reload });
//校验license
const validateLicense = async () => {
    const [err, res]: any = await to(loginApi.validateLicense());
    if (err) {
        Message.error(t(`content.statusCode.${err.code}`));
        return;
    }
    if (res) {
        if (res.data.flag === false) {
            sessionStorage.clear();
            router.replace('/license');
        }
    }
};
</script>
<style>
@font-face {
    font-family: shs;
    src: url('./fonts/SourceHanSansCN-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}
#app {
    height: 100%;
    font-size: 14px;
    font-family: shs, Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* min-width: 1400px; */
}
</style>
