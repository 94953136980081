<template>
    <bre-layout-sider hide-trigger collapsible :collapsed="isCollapse" class="sidebar-container" :width="240">
        <div class="top bre-flex" :style="{ display: isCollapse ? 'none' : 'flex' }">
            <div class="logo-container bre-flex">
                <div v-if="logoPath==='1'"></div>
                <img v-else-if="logoPath" class="logo" :src="logoPath" alt="">
                <div v-else class="logo-img"></div>
                <!-- <img v-else class="logo" src="@/assets/images/logo.png" alt="" /> -->
            </div>
            <div class="collapse-container bre-flex" @click="collapseSidebar">
                <div class="collapse"></div>
                <!-- <img class="collapse" src="@/assets/images/icon-left.png" alt="" /> -->
            </div>
        </div>
        <div class="logo-container-simple bre-flex" :style="{ display: isCollapse ? 'flex' : 'none' }">
            <!-- <img v-if="logoPath" class="logo" :src="logoPath" alt=""> -->
            <div class="logo-img" @click="collapseSidebar"></div>
            <!-- <img v-else class="logo" src="@/assets/images/logo-simple.png" alt="" /> -->
            <!-- <div v-if="logoPath" class="logo" :style="{
                    backgroundImage: `url(${logoPath?`${logoPath}`:'/src/assets/images/logo-simple.png'})`
                }" /> -->
        </div>
        <bre-menu :default-selected-keys="activeMenuItem" :default-open-keys="activeMenu" :style="{ width: '100%' }" :collapsed="isCollapse"
            @menu-item-click="menuClick" class="menu-container" accordion>
            <sidebar-item v-for="route in routes" :key="route.path" :item="route" :base-path="route.path" />
        </bre-menu>
    </bre-layout-sider>
    <!-- <div class="collapse-container-right" :style="{ display: isCollapse ? 'flex' : 'none' }" @click="collapseSidebar">
        <div class="collapse"></div>
    </div> -->
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, toRaw, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAppStore } from '@/store/app';
import { useRouterStore } from '@/store/router';
import SidebarItem from './SidebarItem.vue';
// import Logo from './Logo.vue';
import loginApi from '@/api/login';
import systemApi from '@/api/system-config/system';
import to from 'await-to-js';

// const showLogo = ref(
//     import.meta.env.VITE_SIDEBAR_LOGO === 'true' ? true : false
// );
const useApp = useAppStore();
const useRouterContent = useRouterStore();
const route = useRoute();
const router = useRouter();
const hostAddress = computed(() => useApp.hostAddress);
const activeMenu = computed(() => {
    // 获取当前router
    const { path } = toRaw(route);
    const menu = path.value.substring(0, path.value.lastIndexOf('/'));
    return [menu];
});
const activeMenuItem = computed(() => {
    // 获取当前router
    const { path } = toRaw(route);
    return [path?.value];
});
const isCollapse = computed(() => !useApp.sidebar.opened);
// const isCollapse = computed(() => !useApp.sidebar.opened);
const routes = computed(() => useRouterContent.routes);
const menuClick = (key) => {
    router.push(key);
};
const collapseSidebar = () => {
    useApp.toggleSideBar();
};
const logoPath = ref('1');
const queryLogo = async () => {
    const [error, result] = await to(systemApi.getCustomerCode());
    if (error) {
        return;
    }
    const [err, res] = await to(loginApi.getLogo());
    if (err) {
        if (result.code === 200) {
            if (result.data.customercode === '1') {
                logoPath.value =
                    'http://' +
                    hostAddress.value +
                    '/customize/logo-bg.png?' +
                    Math.random();
            } else if (result.data.customercode === '0') {
                logoPath.value =
                    'http://' +
                    hostAddress.value +
                    '/customize/logo-bp.png?' +
                    Math.random();
            }
            return;
        }
    }
    if (res?.code === 200) {
        logoPath.value =
            'http://' +
            hostAddress.value +
            '/customize/logo-cus.png?' +
            Math.random();
        return;
    }
};

onMounted(() => {
    setTimeout(() => {
        queryLogo();
    });
});
watch(
    () => useApp.logoSync,
    (value) => {
        if (value) {
            setTimeout(() => {
                queryLogo();
            });
            useApp.setLogoSync(false);
        }
    }
);
</script>
<style lang="scss" scoped>
.collapse-container-right {
    position: absolute;
    top: 34px;
    left: 55px;
    z-index: 999;
    height: 50px;
    cursor: pointer;
    .collapse {
        cursor: pointer;
        width: 50px;
        height: 50px;
        @include theme {
            background-image: t('icon-right');
        }
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}
.collapse-container-pop {
    display: flex;
    height: 120px;
    img {
        margin: auto;
    }
}
.sidebar-container {
    overflow: auto;
    .logo-container-simple {
        margin-right: 4px;
        height: 120px;
        .logo,
        .logo-img {
            width: 16px;
            height: 16px;
            object-fit: contain;
        }
        .logo-img {
            cursor: pointer;
            @include theme {
                background-image: t('logo-simple-icon');
            }
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
    .logo-container {
        margin-left: 0px;
        padding-left: 24px;
        width: 200px;
        height: 120px;
        justify-content: start;
        .logo,
        .logo-img {
            width: 150px;
            max-height: 50px;
            object-fit: contain;
        }
        .logo-img {
            @include theme {
                background-image: t('logo-icon');
            }
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
    .collapse-container {
        height: 20px;
        cursor: pointer;
        .collapse {
            width: 16px;
            height: 16px;
            margin-right: 24px;
            @include theme {
                background-image: t('logo-simple-icon');
            }
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
    .menu-container {
        height: calc(100vh - 120px);
        font-size: 16px;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}
</style>
<style lang="scss">
.arco-layout-sider-collapsed.sidebar-container {
    width: 80px !important;
}
.sidebar-container {
    width: 240px !important;
    .arco-layout-sider-children {
        overflow: hidden;
    }
    .arco-menu-light {
        background-color: transparent;
        .arco-menu-pop-header,
        .arco-menu-pop-header:hover {
            padding: 0 12px;
            background-color: transparent;
        }
        .arco-menu-inner {
            margin: 0;
            padding: 0;
            .arco-menu-item {
                height: 50px;
                color: rgba(255, 255, 255, 0.6);
                background-color: transparent;
                line-height: 50px;
                .arco-menu-title {
                    text-align: left;
                }
            }
            .arco-menu-item:not(.arco-menu-has-icon) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: pre-wrap;
            }
            .arco-menu-selected {
                .svg-icon,
                .arco-menu-title {
                    color: #ffffff;
                }
            }
        }
        .arco-menu-inline-header.arco-menu-selected .arco-icon {
            color: #ffffff;
        }
    }
    .arco-menu-light:not(.arco-menu-pop) {
        .arco-menu-item {
            .arco-menu-title {
                text-align: left;
            }
            .arco-menu-icon {
                display: flex;
                .svg-icon {
                    margin: auto 6px auto 26px;
                }
            }
        }
        .arco-menu-selected {
            .svg-icon,
            .arco-menu-title {
                color: #ffffff;
            }
            .item-selected {
                position: absolute;
                top: 5px;
                left: 0;
                display: inline-block;
                width: 6px;
                height: 40px;
                border-radius: 0px 8px 8px 0px;
                background-color: #ffffff;
            }
        }
    }
    .arco-menu-light.arco-menu-pop {
        width: 80px;
        .arco-menu-icon {
            display: flex;
            margin: auto;
            height: 50px;
            .svg-icon {
                margin: auto;
            }
        }
        .arco-menu-title {
            display: none;
        }
        .arco-menu-selected {
            .arco-menu-icon {
                display: flex;
                height: 50px;
                .svg-icon {
                    margin: auto;
                }
                .item-icon-selected {
                    position: absolute;
                    top: 5px;
                    left: 0;
                    display: inline-block;
                    width: 3px;
                    height: 40px;
                    border-radius: 0px 8px 8px 0px;
                    background-color: #ffffff;
                }
            }
        }
    }
    .arco-menu-inline-header {
        padding-right: 0px;
        height: 50px;
        color: rgba(255, 255, 255, 0.6) !important;
        background-color: transparent !important;
        line-height: 50px;
        &:hover,
        &.arco-menu-selected:hover {
            color: rgba(255, 255, 255, 0.6);
            background-color: transparent;
        }
        span,
        p {
            font-size: 16px;
        }
        .arco-menu-icon-suffix {
            position: absolute;
            right: 20px;
            color: rgba(255, 255, 255, 0.6);
            .arco-icon {
                color: rgba(255, 255, 255, 0.6);
            }
        }
        .arco-menu-icon {
            display: flex;
            .svg-icon {
                margin: auto 6px auto 26px;
            }
        }
    }
    .arco-menu-inline-content {
        .arco-menu-item {
            height: 50px;
            color: rgba(255, 255, 255, 0.6);
            background-color: transparent;
        }
    }
    .menu-container {
        ::-webkit-scrollbar {
            display: none;
        }
    }
}
.arco-trigger-menu-item {
    .item-selected {
        display: inline-block;
    }
}
</style>
