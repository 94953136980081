import { get, post, getBlob, form, put } from '@/utils/request';

export default {
    login: (query: any) => post('/api/v1/user/login', query), // 登录
    validateLicense: () => get('/bars/v1/license/check'), // 校验license
    logout: (query: any) => post('/api/v1/user/logout', query), // 登出
    uploadLicense: (query: any) => form('/bars/v1/license/upload', query), // 上传license
    editUser: (id: string, query: any) => put('/api/v1/user/' + id, query), //登录页修改用户
    setQuestion: (query: any) => put('/api/v1/user/question', query), //设置密保问题
    verifyQuestion: (query: any) => post('/api/v1/user/question', query), //验证密保
    getAllQuestion: () => get('/api/v1/user/question'), // 获取所有密保问题
    getLogo: () => get('/api/v1/system/customize/logo'), // 获取logo

    userCount: () => get('/api/v1/user/count'), // 判断用户是否第一次登录
    register: (query: any) => post('/api/v1/user/register', query), // 注册超管
    licenseDownload: () => getBlob('/api/v1/license/download'), // 下载硬件指纹文件
    updatePassword: (id: string, query: any) =>
        put('/api/v1/user/password/' + id, query), // 修改密码
    getUserInfo: () => get('/api/v1/user/authuser/info'), // 登录后获取用户信息
    checkUser: (query: { username: string }) =>
        get('/api/v1/user/check', query), // 校验用户是否存在,
    getLicense: () => get('/bars/v1/license/check')
};
