import { get, post, postData, put, deleteReq } from '@/utils/request';

export interface SyncChannelQuery {
    origin_channel: number;
    smart_rule_list: Array<number>;
    target_channel: Array<number>;
}
export interface GuardTimeQuery {
    channel_id: number;
    algo_type: string;
}
export interface EditGuardTimeQuery extends GuardTimeQuery {
    control_time: object;
}
export default {
    // 同步点位智能任务
    syncChannel: (query: SyncChannelQuery) =>
        postData('/api/v1/channel/sync', query),
    // 修改告警视频参数
    editAlarmVideo: (query: any) => put('/api/v1/channel/alarm_video', query),
    // 修改告警恢复开关
    editWarnSwitch: (query: any) => put('/api/v1/channel/recovery', query),
    // 智能分析任务
    // 获取智能分析任务列表
    getRuleList: (id: number) => get('/api/v1/rule/list/' + id),
    // 智能分析任务详细信息
    getRuleInfo: (id: number) => get('/api/v1/rule/info/' + id),
    // 添加智能分析任务
    addRule: (id: number, query: any) => post('/api/v1/rule/' + id, query),
    // 删除智能分析任务
    deleteRule: (id: number) => deleteReq('/api/v1/rule/' + id),

    // 布防时间
    // 获取布防时间
    getGuardTime: (channelid: any, tasktype: any) =>
        get(`/api/v1/smart/config/weekplan/${channelid}/${tasktype}`),
    // 编辑布防时间
    editGuardTime: (channelid: any, tasktype: any, query: any) =>
        put(`/api/v1/smart/config/weekplan/${channelid}/${tasktype}`, query),

    getLinkageCfg: (channelid: any, tasktype: any) =>
        get('/api/v1/smart/config/linkage/' + channelid + '/' + tasktype),
    editLinkageCfg: (channelid: any, tasktype: any, query: any) =>
        put(
            '/api/v1/smart/config/linkage/' + channelid + '/' + tasktype,
            query
        ),

    // 离开页面时，下发分析任务给算法
    putTask: (id: number) => post('/api/v1/analyser/' + id),

    // 获取算力、修改算力
    getTaskList: () => get('/api/v1/smart/config/hashrate'),
    getOptionalPower: (type, channelid, query) =>
        post(
            '/api/v1/smart/config/task/hashrate/' + type + '/' + channelid,
            query
        ),

    getAlgCfg: (channelid) => get('/api/v1/smart/config/task/' + channelid),
    getAbility: () => get('/api/v1/smart/config/ability'),
    editAlgCfg: (channelid, query) =>
        put('/api/v1/smart/config/task/' + channelid, query)
};
