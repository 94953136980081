import { defineComponent, onMounted, computed } from 'vue';
import { useAppStore } from '@/store/app';
export default defineComponent({
    setup() {
        const useApp = useAppStore();
        const device = computed(() => useApp.device);

        onMounted(() => {
            fixBugIniOS();
        });
        const fixBugIniOS = () => {
            const $subMenu = this.$refs.subMenu;
            if ($subMenu) {
                const handleMouseleave = $subMenu.handleMouseleave;
                $subMenu.handleMouseleave = (e) => {
                    if (this.device === 'mobile') {
                        return;
                    }
                    handleMouseleave(e);
                };
            }
        };
        return {
            device,
            fixBugIniOS
        };
    }
});
