import { asyncRoutes, constantRoutes } from '@/router/index';
import { defineStore } from 'pinia';
import { RouteRecordRaw } from 'vue-router';

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles: string[], route: any) {
    if (route.meta && route.meta.roles) {
        return roles.some((role) => route.meta.roles.includes(role));
    }
    // if (!route.meta.hasOwnProperty('role')) {
    //     return false;
    // }
    return true;
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes: RouteRecordRaw[], roles: string[]) {
    const res = <RouteRecordRaw[]>[];

    routes.forEach((route) => {
        const tmp = { ...route };
        if (hasPermission(roles, tmp)) {
            if (tmp.children) {
                tmp.children = filterAsyncRoutes(tmp.children, roles);
            }
            res.push(tmp);
        }
    });
    return res;
}

export const usePermissionStore = defineStore({
    id: 'permission', // id必填，且需要唯一
    state: () => ({
        routes: <RouteRecordRaw[]>[],
        addRoutes: <RouteRecordRaw[]>[]
    }),
    actions: {
        generateRoutes(roles: string[]) {
            return new Promise((resolve) => {
                let accessedRoutes;
                let userId: any = sessionStorage.getItem('userId')
                    ? sessionStorage.getItem('userId')
                    : '';
                if (roles.includes('admin') || userId === 1) {
                    accessedRoutes = asyncRoutes || [];
                } else {
                    accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
                }
                // accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
                // if (accessedRoutes && accessedRoutes.length > 0) {
                //     accessedRoutes.forEach((element) => {
                //         if (element.children && element.children.length > 0) {
                //             element.redirect = element.children[0].path;
                //             return;
                //         }
                //     });
                // }
                this.addRoutes = accessedRoutes;
                this.routes = constantRoutes.concat(accessedRoutes);
                resolve(accessedRoutes);
            });
        }
    }
});
