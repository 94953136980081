import { get, post, put } from '@/utils/request';
import { PageQuery } from '@/store/app';

export interface SetTimeQuery {
    time_sync_type: number;
    time: number;
    is_ntp_server: number;
    ntp_addr: string;
    npt_interval: number;
}
export interface AddOperatorQuery {
    op_name: string;
    ip: string;
    port: string;
}
export interface EditOperatorQuery extends AddOperatorQuery {
    op_name: string;
    ip: string;
    port: string;
}
export interface LogoQuery {
    title: string;
    picBase64: string;
    title_source: string;
}
export interface TitleQuery {
    nav_title: string;
    login_title: string;
}
export interface EditAlgQuery {
    status: number;
    id: number;
    title: string;
}
export interface RegularRebootQuery {
    reboot_time: number;
    state: number;
}
export interface DeviceResetQuery {
    retain_config: number;
}
export interface CoverConfigQuery {
    switch: number;
    type: number;
    num: number;
}
export default {
    // 重启设备
    rebootDevice: (query) => put('/bars/v1/patch/reboot', query),
    // 还原设备
    resetDevice: (query) => put('/bars/v1/patch/factory/reset', query),
    // 版本升级
    versionUpdate: (query: any) => post('/bars/v1/patch/upload', query),
    updateService: (query) => post('/bars/v1/patch/update', query),
    updateServiceAll: (query) => post('/bars/v1/patch/update_all', query),
    updateStatus: (query) => get('/bars/v1/patch/update/status', query),
    // 远程升级
    remoteUpdateUrl: (query) => put('/bars/v1/patch/ws_update', query),
    getRemoteUpdateUrl: (query) => get('/bars/v1/patch/ws_update', query),
    remoteUpdateStatus: (query) =>
        get('/bars/v1/patch/ws_update/status', query),
    // 诊断信息导出
    exportDiagnosisInfo: (query) => get('/bars/v1/logs/packagepath', query),
    // 获取诊断信息导出状态
    getDiagnosisStatus: (query) => get('/bars/v1/logs/packagetask', query),
    // 配置信息导入
    configInfoImport: (query: any) =>
        post('/bars/v1/config/export/all/import', query),
    // 配置信息导出
    exportConfig: (query) => post('/bars/v1/config/export/all/export', query),

    // 安全中心
    // root密码修改
    editRoot: (query) => put('/bars/v1/patch/safe/passwd', query),
    editFire: (query) => put('/bars/v1/patch/safe/iptables', query),

    // 获取操作日志信息
    getLog: (query: PageQuery) => post('/api/v1/log', query),
    // 获取版本信息
    getVersion: () => get('/api/v1/system/baseinfo'),
    // 获取时间设置
    getTimeConfig: () => get('/api/v1/system/time'),
    // 设置时间
    setTime: (query: SetTimeQuery) => post('/api/v1/system/time', query),
    // 测试NTP服务器是否能ping通
    pingNTP: (query: { ip: string }) => post('/api/v1/init/ntp', query),

    // 个性化配置
    // 获取自定义logo是否存在
    getLogo: () => get('/api/v1/system/customize/logo'),
    editLogo: (query: { picBase64: string }) =>
        put('/api/v1/system/customize/logo', query),
    // 自定义标题
    editTitle: (query: TitleQuery) =>
        put('/api/v1/system/customize/title', query),
    // 获取自定义标题
    getTitle: () => get('/api/v1/system/customize/title'),

    editAudio: (query) => post('/bars/v1/patch/upload/audio', query),
    // 获取算法列表
    getAlgList: (query) => get('/api/v1/algo', query),
    // 修改算法列表
    editAlgList: (query: Array<EditAlgQuery>) => put('/api/v1/algo', query)
};
