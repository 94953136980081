import { get, put } from '@/utils/request';
export default {
    getBase: () => get('/api/v1/system/base'),
    updateBase: (query) => put('/api/v1/system/base', query),
    getTime: () => get('/api/v1/system/time'),
    updateTime: (query) => put('/api/v1/system/time', query),
    getNTP: () => get('/api/v1/system/time/ntp'),
    updateNTP: (query) => put('/api/v1/system/time/ntp', query),

    // getRunDay: (query) => get("/api/v1/system/starttime", query),
    getRunDay: (query) => get('/api/v1/system/runtime', query),
    // 判断是否为白牌模式
    getCustomerCode: (query) => get('/api/v1/system/customercode', query),
    // 获取能力接口
    getAlgAbility: () => get('/api/v1/smart/config/config/ability')
};
