<template>
    <div v-if="!item.meta || !item.meta.hidden" class="menu-wrapper">
        <template v-if="
                hasOneShowingChild(item.children, item) &&
                (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
                !item.alwaysShow
            ">
            <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
                <bre-menu-item :key="resolvePath(onlyOneChild.path)" :class="{ 'submenu-title-noDropdown': !isNest }">
                    <div class="item-selected"></div>
                    <template #icon>
                        <div class="item-icon-selected"></div>
                        <svg-icon :name="onlyOneChild.meta.icon || (item.meta && item.meta.icon)" class="icon"></svg-icon>
                    </template>
                    {{ t(onlyOneChild?.meta?.title) }}
                </bre-menu-item>
            </app-link>
        </template>
        <bre-sub-menu v-else :key="resolvePath(item.path)">
            <template #icon>
                <div class="item-icon-selected"></div>
                <svg-icon :name="item.meta.icon" class="icon"></svg-icon>
            </template>
            <template #title>
                {{ t(item?.meta?.title) }}
            </template>
            <bre-menu-item v-for="child in item.children" :key="child.path">
                <div class="item-selected"></div>
                <template #icon>
                    <svg-icon :name="child.meta.icon || (child.meta && child.meta.icon)" class="icon"></svg-icon>
                </template>
                {{ t(child?.meta?.title) }}
            </bre-menu-item>
        </bre-sub-menu>
    </div>
</template>

<script>
import path from 'path-browserify';
import { validExternal } from '@/utils/validate';
import AppLink from './LinkComponent.vue';
import FixiOSBug from './FixiOSBug.js';
import { useI18n } from 'vue-i18n';

export default {
    name: 'SidebarItem',
    components: {
        AppLink
    },
    mixins: [FixiOSBug],
    props: {
        // route object
        item: {
            type: Object,
            required: true
        },
        isNest: {
            type: Boolean,
            default: false
        },
        basePath: {
            type: String,
            default: ''
        }
    },
    data() {
        this.onlyOneChild = null;
        return {};
    },
    setup() {
        const { t } = useI18n();
        return {
            t
        };
    },
    methods: {
        hasOneShowingChild(children = [], parent) {
            const showingChildren = children.filter((item) => {
                if (item.hidden) {
                    return false;
                }
                // Temp set(will be used if only has one showing child)
                this.onlyOneChild = item;
                return true;
            });

            // When there is only one child router, the child router is displayed by default
            if (showingChildren.length === 1) {
                return true;
            }

            // Show parent if there are no child router to display
            if (showingChildren.length === 0) {
                this.onlyOneChild = {
                    ...parent,
                    path: '',
                    noShowingChildren: true
                };
                return true;
            }

            return false;
        },
        resolvePath(routePath) {
            if (validExternal(routePath)) {
                return routePath;
            }
            if (validExternal(this.basePath)) {
                return this.basePath;
            }
            return path.resolve(this.basePath, routePath);
        }
    }
};
</script>