// 自适应
import _ from 'lodash';

// 以1920px 底图为准开发页面
export const setDomFontSize = (): void => {
    const width =
        document.documentElement.clientWidth || document.body.clientWidth;
    const fontsize = (width <= 1440 ? 1440 : width) / 100 + 'px';
    (document.getElementsByTagName('html')[0].style as any)['font-size'] =
        fontsize;
    if (width <= 1440) {
        (document.getElementsByTagName('html')[0].style as any).width =
            '1440px';
    } else {
        (document.getElementsByTagName('html')[0].style as any).width =
            width + 'px';
    }
};
const setDomFontSizeDebounce = _.debounce(setDomFontSize, 0);
window.addEventListener('resize', setDomFontSizeDebounce); // 浏览器加入收缩监听防抖，重新计算rem配置
