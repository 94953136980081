import { resetRouter } from '@/router/index.ts';
import { defineStore } from 'pinia';

export const useUserStore = defineStore({
    id: 'user', // id必填，且需要唯一
    state: () => ({
        token: sessionStorage.getItem('token'),
        name: '',
        avatar: '',
        introduction: '',
        roles: [],
        roleList: {}, // 角色列表
        roleArray: []
    }),
    actions: {
        getInfo() {
            // return new Promise((resolve, reject) => {
            //     // return new Promise((resolve, reject) => {
            //     let menu = sessionStorage.getItem('menu')
            //         ? sessionStorage.getItem('menu').split(',')
            //         : [];
            //     const roles = menu;
            //     this.roles = roles;
            //     console.log(roles);
            //     resolve(roles);
            // });
            let menu = sessionStorage.getItem('menu')
                ? sessionStorage.getItem('menu').split(',')
                : ['admin'];
            const roles = menu;
            this.roles = roles;
            return roles;
            // });
        },
        setRoles() {
            this.roles = roles;
        },
        // remove token
        resetToken() {
            return new Promise((resolve) => {
                this.token = '';
                this.roles = [];
                resolve();
            });
        },
        // dynamically modify permissions
        async changeRoles(role) {
            const token = role + '-token';
            this.token = token;
            const { roles } = await dispatch('getInfo');
            resetRouter();
            // generate accessible routes map based on roles
            const accessRoutes = await dispatch(
                'permission/generateRoutes',
                roles,
                {
                    root: true
                }
            );
            // dynamically add accessible routes
            router.addRoutes(accessRoutes);

            // reset visited views and cached views
            dispatch('tagsView/delAllViews', null, {
                root: true
            });
        },
        setToken() {
            this.token = token;
        },
        setIntroduction(introduction) {
            this.introduction = introduction;
        },
        setName(name) {
            this.name = name;
        },
        setAvatar() {
            this.avatar = name;
        }
    }
});
