import { createI18n, useI18n } from 'vue-i18n';

import enLocale from './en';
import zhLocale from './zh';
import breEnLocale from '@bresee-ui/web-vue/es/locale/lang/en-us';
import breZhLocale from '@bresee-ui/web-vue/es/locale/lang/zh-cn';

const message = {
    en: {
        ...enLocale,
        ...breEnLocale
    },
    zh: {
        ...zhLocale,
        ...breZhLocale
    }
};

/**
 * 切换语言
 * @param {语言} flag
 */
export function changeLanguage(lang: string) {
    sessionStorage.setItem('locale', lang);
    const { locale } = useI18n();
    locale.value = lang;
}

const i18n = createI18n({
    fallbackLocale: 'zh',
    globalInjection: true, // 全局注入 $t 函数
    legacy: false, // 使用 Composition API 模式，则需要将其设置为false
    locale: sessionStorage.getItem('locale') || 'zh',
    messages: message
});

export default i18n;
