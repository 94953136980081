import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/index.vue';
import axios from 'axios';

// 基本路由
export const constantRoutes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/template',
        name: 'Redirect'
    },
    {
        path: '/template',
        name: 'Template',
        component: () => import('@/views/template/index.vue'),
        meta: { title: '', hidden: true }
    },
    {
        path: '/login',
        component: () => import('../views/login/index.vue'),
        meta: {
            hidden: true,
            title: 'content.router.login'
        },
        name: 'Login'
    },
    {
        path: '/license',
        component: () => import('../views/license/index.vue'),
        meta: {
            hidden: true,
            title: 'content.router.license'
        },
        name: 'License'
    },
    {
        path: '/license-overdue',
        component: () => import('../views/license/LicenseOverdue.vue'),
        meta: {
            hidden: true,
            title: 'content.router.license'
        },
        name: 'LicenseOverdue'
    },
    {
        path: '/data-panel',
        component: () => import('../views/data-panel/index.vue'),
        meta: {
            hidden: true,
            title: 'content.router.dataPanel'
        },
        name: 'dataPanel'
    }
];
export const asyncRoutes: Array<RouteRecordRaw> = [
    {
        path: '/id-config',
        component: Layout,
        meta: {
            hidden: true,
            title: 'content.router.empty',
            roles: ['10000000']
        },
        children: [
            {
                path: '/id-config',
                component: () =>
                    import('../views/id-manage/components/ConfigIndex.vue'),
                name: 'idConfig',
                meta: { roles: ['10000000'], title: 'content.router.idConfig' }
            }
        ]
    },
    {
        path: '/id-manage',
        component: Layout,
        meta: {
            title: 'content.router.empty',
            icon: 'id-manage',
            roles: ['10000000']
        },
        children: [
            {
                path: '/id-manage',
                component: () => import('../views/id-manage/index.vue'),
                name: 'idManage',
                meta: {
                    title: 'content.router.idManage',
                    icon: 'id-manage',
                    roles: ['10000000']
                }
            }
        ]
    },
    {
        path: '/network-config',
        component: Layout,
        meta: {
            title: 'content.router.networkConfig',
            icon: 'network',
            roles: ['11040000']
        },
        children: [
            {
                path: '/network-config/network-config',
                component: () =>
                    import('../views/system-config/network-config/index.vue'),
                name: 'ipConfig',
                meta: {
                    title: 'content.router.ipConfig',
                    roles: ['11040000']
                }
            },
            {
                path: '/network-config/nps-config',
                component: () =>
                    import('../views/system-config/nps-config/index.vue'),
                name: 'npsConfig',
                meta: {
                    title: 'content.router.npsConfig',
                    roles: ['11040000']
                }
            }
        ]
    },
    {
        path: '/guard',
        component: Layout,
        meta: {
            title: 'content.router.guard',
            icon: 'guard',
            roles: [
                '12000000',
                '12010000',
                '12020000',
                '12030000',
                '12040000',
                '12050000'
            ]
        },
        children: [
            {
                path: '/guard/work-cloth',
                component: () => import('../views/guard/work-cloth/index.vue'),
                name: 'workCloth',
                meta: {
                    title: 'content.router.workCloth',
                    roles: ['12030000']
                }
            },
            {
                path: '/guard/people',
                component: () => import('../views/guard/people/index.vue'),
                name: 'people',
                meta: {
                    title: 'content.router.people',
                    roles: ['12010000']
                }
            },
            {
                path: '/guard/car-plate',
                component: () => import('../views/guard/car-plate/index.vue'),
                name: 'car-plate',
                meta: {
                    title: 'content.router.carPlate',
                    roles: ['12010000']
                }
            },
            // {
            //     path: '/guard/license-plate',
            //     component: () =>
            //         import('../views/guard/license-plate/index.vue'),
            //     name: 'licensePlate',
            //     meta: {
            //         title: 'content.router.licensePlate',
            //         roles: ['12020000']
            //     }
            // },

            {
                path: '/guard/control-task',
                component: () =>
                    import('../views/guard/control-task/index.vue'),
                name: 'controlTask',
                meta: {
                    title: 'content.router.controlTask',
                    roles: ['12040000']
                }
            },
            {
                path: '/guard/control-data',
                component: () =>
                    import('../views/guard/control-data/index.vue'),
                name: 'controlData',
                meta: {
                    title: 'content.router.controlData',
                    roles: ['12050000']
                }
            },
            {
                path: '/guard/plan-linkage',
                component: () =>
                    import('../views/guard/plan-linkage/index.vue'),
                name: 'planLinkage',
                meta: {
                    title: 'content.router.planLinkage'
                }
            }
        ]
    },
    // {
    //     path: '/analysis',
    //     component: Layout,
    //     meta: {
    //         title: 'content.router.analysis',
    //         icon: 'analysis',
    //         roles: ['15000000', '15010000', '15020000', '15030000', '15040000']
    //     },
    //     children: [
    //         {
    //             path: '/analysis/behavioral-event',
    //             component: () =>
    //                 import('../views/analysis/behavioral-event/index.vue'),
    //             name: 'behavioralEvent',
    //             meta: {
    //                 title: 'content.router.behavioralEvent',
    //                 roles: ['15010000']
    //             }
    //         },
    //         {
    //             path: '/analysis/traffic-event',
    //             component: () =>
    //                 import('../views/analysis/traffic-event/index.vue'),
    //             name: 'trafficEvent',
    //             meta: {
    //                 title: 'content.router.trafficEvent',
    //                 roles: ['15020000']
    //             }
    //         },
    //         {
    //             path: '/analysis/people-counting',
    //             component: () =>
    //                 import('../views/analysis/people-counting/index.vue'),
    //             name: 'peopleCounting',
    //             meta: {
    //                 title: 'content.router.peopleCounting',
    //                 roles: ['15030000']
    //             }
    //         },
    //         {
    //             path: '/analysis/full-structured-snapshot',
    //             component: () =>
    //                 import(
    //                     '../views/analysis/full-structured-snapshot/index.vue'
    //                 ),
    //             name: 'fullStructuredSnapshot',
    //             meta: {
    //                 title: 'content.router.fullStructuredSnapshot',
    //                 roles: ['15040000']
    //             }
    //         }
    //     ]
    // },
    {
        path: '/data-search',
        component: Layout,
        meta: {
            title: 'content.router.empty',
            icon: 'data-search',
            roles: ['14000000', '14010000', '14020000', '14030000', '14040000']
        },
        children: [
            // {
            //     path: '/data-search/work-cloth-snapshot',
            //     component: () =>
            //         import(
            //             '../views/data-search/work-cloth-snapshot/index.vue'
            //         ),
            //     name: 'workClothSnapshot',
            //     meta: {
            //         title: 'content.router.workClothSnapshot',
            //         roles: ['14020000']
            //     }
            // },
            {
                path: '/data-search/behavior-search',
                component: () =>
                    import('../views/data-search/behavior-search/index.vue'),
                name: 'behaviorSearch',
                meta: {
                    title: 'content.router.behaviorSearch',
                    roles: ['14010000']
                }
            }
            // {
            //     path: '/data-search/events-log',
            //     component: () =>
            //         import('../views/data-search/events-log/index.vue'),
            //     name: 'eventsLog',
            //     meta: {
            //         title: 'content.router.eventsLog',
            //         roles: ['14030000']
            //     }
            // },

            // {
            //     path: '/data-search/target-search',
            //     component: () =>
            //         import('../views/data-search/target-search/index.vue'),
            //     name: 'targetSearch',
            //     meta: {
            //         title: 'content.router.targetSearch',
            //         roles: ['14040000']
            //     }
            // }
        ]
    },
    {
        path: '/data-dock',
        component: Layout,
        meta: { roles: ['13000000'], title: 'content.router.empty' },
        children: [
            {
                path: '',
                component: () => import('../views/data-dock/index.vue'),
                name: 'dataDock',
                meta: {
                    title: 'content.router.dataDock',
                    icon: 'data-dock',
                    roles: ['13000000']
                }
            }
        ]
    },
    {
        path: '/system-config',
        component: Layout,
        meta: {
            title: 'content.router.system',
            icon: 'system-config',
            roles: [
                '11000000',
                '11010000',
                '11020000',
                '11030000',
                '11040000',
                '11050000',
                '11060000',
                '11070000',
                '11080000'
            ]
        },
        children: [
            {
                path: '/system-config/user-config',
                component: () =>
                    import('../views/system-config/user-config/index.vue'),
                name: 'userConfig',
                meta: {
                    title: 'content.router.userConfig',
                    roles: ['11010000']
                }
            },
            // {
            //     path: '/system-config/role-config',
            //     component: () =>
            //         import('../views/system-config/role-config/index.vue'),
            //     name: 'RoleConfig',
            //     meta: {
            //         title: 'content.router.roleConfig',
            //         roles: ['11020000']
            //     }
            // },
            // {
            //     path: '/system-config/defender',
            //     component: () =>
            //         import('../views/system-config/defender/index.vue'),
            //     name: 'defender',
            //     meta: {
            //         title: 'content.router.defender',
            //         roles: ['11030000']
            //     }
            // },
            // {
            //     path: '/system-config/version-info',
            //     component: () =>
            //         import('../views/system-config/version-info/index.vue'),
            //     name: 'versionInfo',
            //     meta: {
            //         title: 'content.router.versionInfo',
            //         roles: ['11040000']
            //     }
            // },
            {
                path: '/system-config/defender',
                component: () =>
                    import('../views/system-config/defender/index.vue'),
                name: 'defender',
                meta: {
                    title: 'content.router.defender',
                    roles: ['11030000']
                }
            },
            {
                path: '/system-config/version-info',
                component: () =>
                    import('../views/system-config/version-info/index.vue'),
                name: 'versionInfo',
                meta: {
                    title: 'content.router.versionInfo',
                    roles: ['11040000']
                }
            },

            // {
            //     path: '/system-config/operation-log',
            //     component: () =>
            //         import('../views/system-config/operation-log/index.vue'),
            //     name: 'operationLog',
            //     meta: {
            //         title: 'content.router.operationLog',
            //         roles: ['11050000']
            //     }
            // },
            // {
            //     path: '/system-config/operator-manage',
            //     component: () =>
            //         import('../views/system-config/operator-manage/index.vue'),
            //     name: 'operatorManage',
            //     meta: {
            //         title: 'content.router.operatorManage',
            //         roles: ['11060000']
            //     }
            // },
            {
                path: '/system-config/time-config',
                component: () =>
                    import('../views/system-config/time-config/index.vue'),
                name: 'timeConfig',
                meta: {
                    title: 'content.router.timeConfig',
                    roles: ['11070000']
                }
            },
            // {
            //     path: '/system-config/defender',
            //     component: () =>
            //         import('../views/system-config/defender/index.vue'),
            //     name: 'defender',
            //     meta: {
            //         title: 'content.router.defender',
            //         roles: ['11030000']
            //     }
            // },
            {
                path: '/system-config/security-config',
                component: () =>
                    import('../views/system-config/security-config/index.vue'),
                name: 'securityConfig',
                meta: {
                    title: 'content.router.securityConfig',
                    roles: ['11080000']
                }
            },
            {
                path: '/system-config/personal-config',
                component: () =>
                    import('../views/system-config/personal-config/index.vue'),
                name: 'personalConfig',
                meta: {
                    title: 'content.router.personalConfig',
                    roles: ['11080000']
                }
            },
            {
                path: '/system-config/network',
                component: () =>
                    import('../views/system-config/network-grabbing/index.vue'),
                name: 'networkGrabbing',
                meta: {
                    title: 'content.router.networkGrabbing',
                    roles: ['11080000']
                }
            }
            // 需要控制显隐的界面
            // {
            //     path: '/system-config/resource',
            //     component: () =>
            //         import('../views/system-config/resource-list/index.vue'),
            //     name: 'resourceList',
            //     meta: {
            //         title: 'content.router.resourceList',
            //         roles: ['11080000']
            //     }
            // },
            // {
            //     path: '/system-config/pk',
            //     component: () =>
            //         import('../views/system-config/pk-config/index.vue'),
            //     name: 'pk',
            //     meta: {
            //         title: 'content.router.PK',
            //         roles: ['11080000']
            //     }
            // },
            // {
            //     path: '/system-config/basic',
            //     component: () =>
            //         import('../views/system-config/basic-config/index.vue'),
            //     name: 'basicConfig',
            //     meta: {
            //         title: 'content.router.basicConfig',
            //         roles: ['11080000']
            //     }
            // },
            // {
            //     path: '/system-config/service',
            //     component: () =>
            //         import('../views/system-config/service-list/index.vue'),
            //     name: 'serviceList',
            //     meta: {
            //         title: 'content.router.serviceList',
            //         roles: ['11080000']
            //     }
            // },
            // {
            //     path: '/system-config/api-status',
            //     component: () =>
            //         import('../views/system-config/api-status/index.vue'),
            //     name: 'apiStatus',
            //     meta: {
            //         title: 'content.router.apiStatus',
            //         roles: ['11080000']
            //     }
            // }
        ]
    }
    // {
    //     path: '/PK',
    //     component: Layout,
    //     meta: {
    //         roles: ['16000000'],
    //         title: 'content.router.empty'
    //     },
    //     children: [
    //         {
    //             path: '',
    //             component: () => import('@/views/pk-config/index.vue'),
    //             name: 'PK',
    //             meta: {
    //                 title: 'content.router.PK',
    //                 icon: 'pk',
    //                 roles: ['16000000']
    //             }
    //         }
    //     ]
    // }
];
const baseURL = import.meta.env.VITE_APP_BASE_API?.toString();
const routerQuery: any = {
    baseURL: '/',
    url: baseURL + '/api/v1/smart/config/ability',
    method: 'get',
    withCredentials: true,
    timeout: 10000,
    params: {},
    headers: {
        // Authorization: sessionStorage.getItem('token')
        // Authorization: sessionStorage.getItem("token")
    }
};
axios.request(routerQuery).then((re) => {
    let alg = re.data.data.safeproduction;
    if (
        !alg.includes('allstructurevideovnpf') &&
        !alg.includes('facedetection')
    ) {
        for (let item of asyncRoutes) {
            if (item.path === '/guard') {
                for (let i = 0; i < item.children.length; i++) {
                    if (item.children[i].name === 'people') {
                        item.children.splice(i, 1);
                    }
                }
            }
        }
    }
    if (!alg.includes('workclothesdetection')) {
        for (let item of asyncRoutes) {
            if (item.path === '/guard') {
                for (let i = 0; i < item.children.length; i++) {
                    if (item.children[i].name === 'workCloth') {
                        item.children.splice(i, 1);
                    }
                }
            }
        }
    }
});
// 刷新路由
export const resetRouter = function () {
    const newRouter = router;
    (router as any).matcher = (newRouter as any).matcher; // reset router
};
const router = createRouter({
    history: createWebHashHistory(), // createWebHistory为history模式，createWebHashHistory为hash模式
    routes: constantRoutes
});
export default router;
