import { get } from '@/utils/request';

export default {
    // 获取基础配置
    getConfig: (query) => get('/api/v1/system/base', query),
    // 获取服务列表
    getList: (query) => get('/bars/v1/stats/svrs', query),
    getServiceRunInfo: (id, query) => get('/bars/v1/moniter/svr/' + id, query),
    getLog: (id, query) => get('/bars/v1/logs/svr/' + id, query),
    getApi: (query) => get('/bars/v1/moniter/statis/', query),
    // 工服类型获取 0：未穿工服 1：穿工服
    getClothesType: () => get('/api/v1/smart/config/workclothes/type')
};
