<template>
    <svg :class="svgClass" v-bind="$attrs" :style="{ color: color }">
        <use :xlink:href="iconName" />
    </svg>
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    color: {
        type: String,
        default: ''
    }
});

const iconName = computed(() => `#icon-${props.name}`);
const svgClass = computed(() => {
    if (props.name) {
        return `svg-icon icon-${props.name}`;
    }
    return 'svg-icon';
});
</script>

<style lang="scss">
.svg-icon {
    width: 1em !important;
    height: 1em !important;
    fill: currentColor;
    vertical-align: -1px !important;
}
</style>
