<template>
    <bre-layout class="bre-scroll">
        <bre-layout-content>
            <section class="app-main bre-scroll">
                <keep-alive :include="cachedViews">
                    <router-view :key="key" />
                </keep-alive>
            </section>
            <bre-back-top target-container="#appMain" :style="{ position: 'absolute' }" />
        </bre-layout-content>
    </bre-layout>
</template>

<script lang='ts' setup>
import { computed, toRaw } from 'vue';
import { useRoute } from 'vue-router';
import { useTagsViewStore } from '@/store/tagsView';

const route = useRoute();
const { path } = toRaw(route);
const useTagsView = useTagsViewStore();
const cachedViews = computed(() => useTagsView.cachedViews);
const key = computed(() => path.value);
</script>

<style lang="scss" scoped>
.app-main {
    position: relative;
    overflow: auto;
    padding: 0 30px 30px 30px;
    // min-width: 1500px;
    // min-height: 830px;
    // padding: 20px 30px;
    /* 80= navbar  80  */
    // min-height: calc(100vh - 200px);
}
// .fixed-header + .app-main {
//     padding-top: 80px;
//     padding-left: 100px;
// }
// .hasTagsView {
//     .app-main {
//         /* 114 = navbar + tags-view = 80 + 34 */
//         min-height: calc(100vh - 114px);
//     }
//     .fixed-header + .app-main {
//         padding-top: 114px;
//         padding-left: 20px;
//     }
// }
</style>
