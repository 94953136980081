import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
// import axiosRetry from 'axios-retry';
import { Message } from '@bresee-ui/web-vue';
import { ElLoading } from 'element-plus';
import i18n from '@/language/i18n';
import router from '@/router';
import loadsh from 'lodash';
import to from 'await-to-js';
const baseURL = import.meta.env.VITE_APP_BASE_API?.toString();
export interface ResponseData {
    code: number | string;
    data?: any;
    msg: string;
}
const pendingRequest = new Map(); // 请求对象
const CancelToken = axios.CancelToken;
// 获取请求key
const getReqKey = () => {
    // 请求方式、请求地址、请求参数生成的字符串来作为是否重复请求的依据
    // const { method, url, params, data } = config; // 解构出来这些参数
    // // GET ---> params  POST ---> data
    // const requestKey = [
    //     method,
    //     url,
    //     JSON.stringify(params),
    //     JSON.stringify(data)
    // ].join('&');
    // return requestKey;
};

// 取消重复请求
const removeReqKey = () => {
    // if (pendingRequest.has(key)) {
    //     const cancelToken = pendingRequest.get(key);
    //     cancelToken(key); // 取消之前发送的请求
    //     pendingRequest.delete(key); // 请求对象中删除requestKey
    // }
};
// 创建 axios 实例
const service: AxiosInstance | any = axios.create({
    baseURL: baseURL, // api 的 base_url
    timeout: 10000 // 请求超时时间
});

// 设置请求失败重试;
// axiosRetry(service, {
//     retries: 3, // 自动发送请求次数
//     retryDelay: (retryCount) => {
//         return retryCount * 1500; // 重复请求延迟（毫秒）
//     },
//     shouldResetTimeOut: true, // 重置超时时间，失败请求的下一次与该次的时间间隔是否要重置
//     // true：打开自动发送请求，false：关闭自动发送请求
//     retryCondition: (error) => {
//         if (
//             error.message.includes('timeout') ||
//             error.message.includes('status code')
//         ) {
//             return true;
//         } else {
//             return false;
//         }
//     }
// });
// loading实例
let loadingInstance: any = null;

let configInfo: any = null;

// request 拦截器 axios 的一些配置
service.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        configInfo = config.url;
        if (
            config.url !== '/api/v1/smart/workclothlib/members/import' &&
            config.url !== '/api/v1/system/grab_bag' &&
            config.url !== '/api/v1/media/status' &&
            config.url !== '/bars/v1/logs/packagetask' &&
            config.url !== '/api/v1/system/grab_bag/status' &&
            config.url !== '/bars/v1/patch/update/status' &&
            config.url !== '/api/v1/system/time'
        ) {
            loadingInstance = ElLoading.service({
                text: i18n.global.t('content.loading') + '...',
                // spinner: 'loading',
                background: 'rgba(255, 255, 255, 0.5)'
            });
        }

        config.headers = Object.assign(
            {
                Authorization: sessionStorage.getItem('token')
            },
            config.headers
        );
        if (
            config.url !== '/api/v1/logo' &&
            config.url !== '/api/v1/system/time' &&
            config.url !== '/api/v1/license/info'
        ) {
            let requestKey = getReqKey(config);
            // 判断是否是重复请求
            if (pendingRequest.has(requestKey)) {
                // 是重复请求
                removeReqKey(requestKey); // 取消
            } else {
                // 设置cancelToken
                config.cancelToken = new CancelToken(function executor(cancel) {
                    pendingRequest.set(requestKey, cancel); // 设置
                });
            }
        }

        return config;
    },
    (error: any) => {
        // Do something with request error
        Promise.reject(error);
    }
);
// respone 拦截器 axios 的一些配置
service.interceptors.response.use(
    (res: AxiosResponse) => {
        if (configInfo !== '/api/v1/system/time') {
            loadingInstance.close();
        }
        let requestKey = getReqKey(res.config);
        removeReqKey(requestKey);
        // Some example codes here:
        // code == 0: successw
        if (res.status === 200) {
            const data: ResponseData = res.data;
            if (data instanceof Blob) {
                return Promise.resolve({ headers: res.headers, data });
            }
            if (
                String(data.code) === '0' ||
                String(data.code) === '200' ||
                String(data.code) === '400940'
            ) {
                return Promise.resolve(data);
            }
            const whitelist = ['1003', '1004'];
            if (whitelist.includes(String(data.code))) {
                Message.info(i18n.global.t(`statusCode.${data.code}`));
            } else {
                if (data.code === 1001) {
                    if (res.config.url !== '/api/v1/system/customize/logo') {
                        Message.error(i18n.global.t(`statusCode.${data.code}`));
                    }
                } else {
                    if (data.code !== 2098 && data.code !== 2082) {
                        Message.error(i18n.global.t(`statusCode.${data.code}`));
                    }
                }
                return Promise.reject(data);
            }
        } else if (res.status === 206) {
            // Message.error(res.data.msg);
            return Promise.reject(new Error(res.data.msg || 'Error'));
        } else {
            return Promise.reject(new Error(res.data.msg || 'Error'));
        }
    },
    async (error: any) => {
        loadingInstance.close();
        let requestKey = getReqKey(error.config);
        removeReqKey(requestKey);
        if (!error.response) {
            return Promise.reject({});
        }
        const status: any = loadsh.get(error, 'response.status');
        const data: any = loadsh.get(error, 'response.data');
        if (status === 400) {
            if (data.code === 400707) {
                sessionStorage.clear();
                // router.replace({ path: '/license' }).then(() => {
                //     location.reload();
                // });
                router.replace({ path: '/license' });
            }
        }
        if (status && status === 401) {
            const query1: any = {
                baseURL: baseURL,
                url: '/api/v1/user/login',
                method: 'post',
                withCredentials: true,
                timeout: 10000,
                data: {
                    name: sessionStorage.getItem('name'),
                    pwd: sessionStorage.getItem('pwd')
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            //重新进行登录获取新token
            const [err, loginRes]: any = await to(service.request(query1));
            if (err) {
                return;
            }
            loadingInstance.close();
            if (loginRes.code === 200) {
                sessionStorage.setItem('token', loginRes.data.token);
                const config = {
                    ...error.config
                };
                config.headers.Authorization = loginRes.data.token;
                //重复发送原请求
                const [againErr, againRes]: any = await to(
                    service.request(config)
                );
                if (againRes.code === 200) {
                    return Promise.resolve(againRes);
                } else {
                    return Promise.reject(againErr);
                }
            }
        } else {
            if (status === 502) {
                return;
            }
            if (data && status !== 401) {
                const code = data.code;
                Message.error(i18n.global.t(`statusCode.${code}`));
            }
            return Promise.reject(error.response.data);
        }
    }
);

// get 请求方法
export const get = (url: string, params?: any) =>
    new Promise((resolve, reject) => {
        service
            .request({
                url: url,
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                },
                params: params,
                timeout: Infinity
            })
            .then((res: any) => {
                resolve(res);
            })
            .catch((error: any) => {
                reject(error);
            });
    });
export const getBlob = (url: string, params?: any) =>
    new Promise((resolve, reject) => {
        service
            .request({
                url: url,
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                },
                params: params,
                timeout: Infinity,
                responseType: 'blob'
            })
            .then((res: any) => {
                resolve(res);
            })
            .catch((error: any) => {
                reject(error);
            });
    });
// get 请求方法 超时时间更久
export const getData = (url: string, params?: any) =>
    new Promise((resolve, reject) => {
        service
            .request({
                url: url,
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                },
                timeout: Infinity,
                params: params
            })
            .then((res: any) => {
                resolve(res);
            })
            .catch((error: any) => {
                reject(error);
            });
    });
// post 请求方法
export const post = (url: string, params?: any) =>
    new Promise((resolve, reject) => {
        service
            .request({
                url: url,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                },
                timeout: Infinity,
                data: params
            })
            .then((res: any) => {
                resolve(res);
            })
            .catch((error: any) => {
                reject(error);
            });
    });
// post 请求方法 超时时间更久
export const postData = (url: string, params?: any) =>
    new Promise((resolve, reject) => {
        service
            .request({
                url: url,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                },
                timeout: Infinity,
                data: params
            })
            .then((res: any) => {
                resolve(res);
            })
            .catch((error: any) => {
                reject(error);
            });
    });
export const postBlob = (url: string, params?: any) =>
    new Promise((resolve, reject) => {
        service
            .request({
                url: url,
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: sessionStorage.getItem('token')
                },
                data: params,
                timeout: Infinity
                // responseType: 'blob'
            })
            .then((res: any) => {
                resolve(res);
            })
            .catch((error: any) => {
                reject(error);
            });
    });
// put请求方法,超时时间10s
export const put = (url: string, params?: any) =>
    new Promise((resolve, reject) => {
        service
            .request({
                url: url,
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                },
                data: params,
                timeout: Infinity
            })
            .then((res: any) => {
                resolve(res);
            })
            .catch((error: any) => {
                reject(error);
            });
    });
export const putLimit = (url: string, params?: any) =>
    new Promise((resolve, reject) => {
        service
            .request({
                url: url,
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                },
                data: params,
                timeout: 10000
            })
            .then((res: any) => {
                resolve(res);
            })
            .catch((error: any) => {
                reject(error);
            });
    });
// put请求方法，超时时间更久
export const putData = (url: string, params?: any) =>
    new Promise((resolve, reject) => {
        service
            .request({
                url: url,
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                },
                timeout: Infinity,
                data: params
            })
            .then((res: any) => {
                resolve(res);
            })
            .catch((error: any) => {
                reject(error);
            });
    });
// delete请求方法
export const deleteReq = (url: string, params?: any) =>
    new Promise((resolve, reject) => {
        service
            .request({
                url: url,
                method: 'delete',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                },
                timeout: Infinity,
                data: params
            })
            .then((res: any) => {
                resolve(res);
            })
            .catch((error: any) => {
                reject(error);
            });
    });
// form请求方法
export const form = (url: string, params?: any) =>
    new Promise((resolve, reject) => {
        service
            .request({
                url: url,
                method: 'post',
                headers: Object.assign({
                    Accept: 'multipart/form-data',
                    Authorization: sessionStorage.getItem('token')
                }),
                data: params
            })
            .then((res: any) => {
                resolve(res);
            })
            .catch((error: any) => {
                reject(error);
            });
    });
