/**
 * @param {string} path
 * @returns {Boolean}
 */
export function validExternal(path: string) {
    return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str: string) {
    const tmp = ['admin', 'editor'];
    return tmp.indexOf(str.trim()) >= 0;
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url: string) {
    const reg =
        /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
    return reg.test(url);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str: string) {
    const reg = /^[a-z]+$/;
    return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str: string) {
    const reg = /^[A-Z]+$/;
    return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str: string) {
    const reg = /^[A-Za-z]+$/;
    return reg.test(str);
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email: string) {
    const reg =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(email);
}

export function validChinese(str: string) {
    const reg = /[\u4e00-\u9fa5]+/;
    return reg.test(str);
}

export function validBlank(str: string) {
    return str.indexOf(' ') > -1;
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validString(str: string) {
    if (typeof str === 'string' || str instanceof String) {
        return true;
    }
    return false;
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function validArray(arg: string) {
    if (typeof Array.validArray === 'undefined') {
        return Object.prototype.toString.call(arg) === '[object Array]';
    }
    return Array.isArray(arg);
}

// 校验名称等长度、是否包含特殊符号等
export function validName(name) {
    const reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{1,99}$/;
    return reg.test(name);
}
// 校验用户名等长度、是否包含特殊符号等
export function validUserName(name) {
    const reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{1,20}$/;
    return reg.test(name);
}
// 校验monitorid
export function validMonitorid(name) {
    const reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{1,20}$/;
    return reg.test(name);
}
// 校验用户名密码
export function validPwd(pwd) {
    const reg = /^[^\u4e00-\u9fa5\u0020]{6,20}$/;
    return reg.test(pwd);
}
// 校验身份证
export function validIdCard(card) {
    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    return reg.test(card);
}
// 校验驾驶证
export function validCarCard(card) {
    const reg = /^\d{12}$/;
    return reg.test(card);
}
// 校验Ip
export function validIp(ip) {
    const reg =
        /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/;
    return reg.test(ip);
}
// 校验数字
export function validNumber(number) {
    const reg = /^[0-9]{1,99}$/;
    return reg.test(number);
}
// 校验root密码
export function validPwdRoot(pwd) {
    const reg = /^[^\u4e00-\u9fa5\u0020]{6,40}$/;
    return reg.test(pwd);
}

/**
 * @param {string} address
 * @returns {Boolean}
 */
export function isAddress(address) {
    const reg =
        /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/;
    const regAddress =
        /^(?=^.{3,255}$)(http(s)?:\/\/)?(www\.)?[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+(:\d+)*(\/\w+\.\w+)*$/;
    if (reg.test(address) || regAddress.test(address)) {
        return true;
    } else {
        return false;
    }
}
//数字校验
export function isPort(port) {
    const reg = /^[0-9]*[1-9][0-9]*$/;
    return reg.test(port);
}
