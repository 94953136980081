import { defineStore } from 'pinia';
import Cookies from 'js-cookie';
import to from 'await-to-js';
import channelConfigApi from '@/api/id-manage/config.ts';
import basicApi from '@/api/system-config/basic.ts';
import i18n from '@/language/i18n';
import systemApi from '@/api/system-config/system';
const baseApi = import.meta.env.VITE_APP_BASE_API?.toString();

const getHostAddress = () => {
    let host = window.document.location.host;
    if (host.indexOf('localhost') !== -1) {
        return '217.1.1.222';
    } else {
        return host + baseApi;
    }
};
const getHostAddressNoPort = () => {
    let host = window.document.location.host;
    if (host.indexOf('localhost') !== -1) {
        return '217.1.1.222';
    } else {
        return host.split(':')[0];
    }
};

export const useAppStore = defineStore({
    id: 'app', // id必填，且需要唯一
    state: () => ({
        warnList: [],
        // {‘英文名’：‘中文名‘}
        warnObj: {},
        // {‘算法类型’：‘绘制类型’}
        warnPaint: {},
        // 判断有什么算法参数
        warnParams: {},
        // 算法参数默认值
        warnParamsDefault: {},
        // 区域配置是否保存 0为未保存
        addTabState: 0,
        hostAddress: getHostAddress(),
        hostAddressNoPort: getHostAddressNoPort(),
        sidebar: {
            opened: Cookies.get('sidebarStatus')
                ? !!+Cookies.get('sidebarStatus')
                : true,
            withoutAnimation: false
        },
        device: 'desktop',
        size: Cookies.get('size') || 'medium',
        fieldIdentify: '',
        theme: 'default',
        companyName: '',
        logo: '',
        backgroundColor: '',
        // 默认语言
        lang: 'zh',
        nation: [],
        province: [],
        city: [],
        country: [],
        idType: {},
        titleSync: false,
        logoSync: false,
        // aibox算法表
        algEvents: {
            facedetection: i18n.global.t('content.algType.facedetection'),
            safehatdetection: i18n.global.t('content.algType.safehatdetection'),
            workclothesdetection: i18n.global.t(
                'content.algType.workclothesdetection'
            ),
            callingdetection: i18n.global.t('content.algType.callingdetection'),
            smokingdetection: i18n.global.t('content.algType.smokingdetection'),
            smokefiredetection: i18n.global.t(
                'content.algType.smokefiredetection'
            ),
            sleepdutydetection: i18n.global.t(
                'content.algType.sleepdutydetection'
            ),
            absentdetection: i18n.global.t('content.algType.absentdetection'),
            passagewayoccupy: i18n.global.t('content.algType.passagewayoccupy'),
            goodsmoveddetetion: i18n.global.t(
                'content.algType.goodsmoveddetetion'
            ),
            regionalinvasion: i18n.global.t('content.algType.regionalinvasion'),
            elcforbid: i18n.global.t('content.algType.elcforbid'),
            firetruckoccupy: i18n.global.t('content.algType.firetruckoccupy'),
            falldetection: i18n.global.t('content.algType.falldetection'),
            pedestriananalysisregion: i18n.global.t(
                'content.algType.pedestriananalysisregion'
            ),
            areapersabndetection: i18n.global.t(
                'content.algType.areapersabndetection'
            ),
            pedestriancountingline: i18n.global.t(
                'content.algType.pedestriancountingline'
            ),
            reflectclothesdetection: i18n.global.t(
                'content.algType.reflectclothesdetection'
            ),
            quicklymoving: i18n.global.t('content.algType.quicklymoving'),
            maskdetection: i18n.global.t('content.algType.maskdetection'),
            climbingdetect: i18n.global.t('content.algType.climbingdetect'),
            loiteringdetect: i18n.global.t('content.algType.loiteringdetect'),
            persongathered: i18n.global.t('content.algType.persongathered'),
            strenousexercise: i18n.global.t('content.algType.strenousexercise'),
            firedetect: i18n.global.t('content.algType.firedetect'),
            fumesdetection: i18n.global.t('content.algType.fumesdetection'),
            playphonedetect: i18n.global.t('content.algType.playphonedetect'),
            cookhatdetect: i18n.global.t('content.algType.cookhatdetect'),
            mousedetect: i18n.global.t('content.algType.mousedetect'),
            chefclothesdetect: i18n.global.t(
                'content.algType.chefclothesdetect'
            ),
            shirtlessdetect: i18n.global.t('content.algType.shirtlessdetect'),
            trashcanfulldetect: i18n.global.t(
                'content.algType.trashcanfulldetect'
            ),

            coverlanddetect: i18n.global.t('content.algType.coverlanddetect'),
            safetybeltdetect: i18n.global.t('content.algType.safetybeltdetect'),
            vehicledriveway: i18n.global.t('content.algType.vehicledriveway'),
            knifestickdetect: i18n.global.t('content.algType.knifestickdetect'),
            allstructurevideovnpf: i18n.global.t(
                'content.algType.allstructurevideovnpf'
            ),
            persondetection: i18n.global.t('content.algType.persondetection'),
            vehicledetection: i18n.global.t('content.algType.vehicledetection'),
            nonvehicledetection: i18n.global.t(
                'content.algType.nonvehicledetection'
            )
        },
        //告警类型
        alarmType: {
            '4': i18n.global.t('content.algType.safehatdetection'),
            '5': i18n.global.t('content.algType.workclothesdetection'),
            '6': i18n.global.t('content.algType.callingdetection'),
            '7': i18n.global.t('content.algType.smokingdetection'),
            '11': i18n.global.t('content.algType.personCapture'),
            '12': i18n.global.t('content.algType.personMatched'),
            '13': i18n.global.t('content.algType.personNotRegistered'),
            '16': i18n.global.t('content.algType.sleepdutydetection'),
            '17': i18n.global.t('content.algType.absentdetection'),
            '18': i18n.global.t('content.algType.passagewayoccupy'),
            '19': i18n.global.t('content.algType.goodsmoveddetetion'),
            '20': i18n.global.t('content.algType.regionalinvasion'),
            '21': i18n.global.t('content.algType.elcforbid'),
            '22': i18n.global.t('content.algType.firetruckoccupy'),
            '31': i18n.global.t('content.algType.cookhatdetect'),
            '32': i18n.global.t('content.algType.mousedetect'),
            '33': i18n.global.t('content.algType.maskdetection'),
            '34': i18n.global.t('content.algType.chefclothesdetect'),
            '35': i18n.global.t('content.algType.pedestriananalysisregion'),
            '45': i18n.global.t('content.algType.pedestriancountingline'),
            '46': i18n.global.t('content.algType.strenousexercise'),
            '57': i18n.global.t('content.algType.loiteringdetect'),
            '58': i18n.global.t('content.algType.quicklymoving'),
            '62': i18n.global.t('content.algType.falldetection'),
            '63': i18n.global.t('content.algType.areapersabndetection'),
            '65': i18n.global.t('content.algType.reflectclothesdetection'),
            '66': i18n.global.t('content.algType.persondetection'),
            '67': i18n.global.t('content.algType.vehicledetection'),
            '671': i18n.global.t('content.algType.vehicledetection1'),
            '672': i18n.global.t('content.algType.vehicledetection2'),
            '68': i18n.global.t('content.algType.nonvehicledetection'),
            '89': i18n.global.t('content.algType.climbingdetect'),
            '91': i18n.global.t('content.algType.persongathered'),
            '108': i18n.global.t('content.algType.fumesdetection'),
            '114': i18n.global.t('content.algType.playphonedetect'),
            '115': i18n.global.t('content.algType.coverlanddetect'),
            '124': i18n.global.t('content.algType.firedetect'),
            '125': i18n.global.t('content.algType.safetybeltdetect'),
            '135': i18n.global.t('content.algType.shirtlessdetect'),
            '136': i18n.global.t('content.algType.trashcanfulldetect'),
            '143': i18n.global.t('content.algType.vehicledriveway'),
            '174': i18n.global.t('content.algType.knifestickdetect'),
            '186': i18n.global.t('content.algType.allstructurevideovnpf')
        },
        algEventsDetail: [
            {
                id: 1,
                title: i18n.global.t('content.algType.facedetection'),
                type: 'safeproduction',
                label: 'facedetection',
                params: [
                    'pupildistance',
                    'effectorspeed',
                    'novideo',
                    'faceopttype'
                ],
                paintType: 'polygon'
            },
            {
                id: 2,
                title: i18n.global.t('content.algType.safehatdetection'),
                type: 'safeproduction',
                label: 'safehatdetection',
                params: ['video'],
                paintType: 'polygon'
            },
            {
                id: 3,
                title: i18n.global.t('content.algType.workclothesdetection'),
                type: 'safeproduction',
                label: 'workclothesdetection',
                params: ['video', 'workclotheslib'],
                paintType: 'polygon'
            },
            {
                id: 4,
                title: i18n.global.t('content.algType.playphonedetect'),
                type: 'safeproduction',
                label: 'playphonedetect',
                params: ['video', 'playphoneduration'],
                paintType: 'polygon'
            },
            {
                id: 5,
                title: i18n.global.t('content.algType.callingdetection'),
                type: 'safeproduction',
                label: 'callingdetection',
                params: ['video'],
                paintType: 'polygon'
            },
            {
                id: 6,
                title: i18n.global.t('content.algType.smokingdetection'),
                type: 'safeproduction',
                label: 'smokingdetection',
                params: ['video'],
                paintType: 'polygon'
            },
            {
                id: 7,
                title: i18n.global.t('content.algType.smokefiredetection'),
                type: 'safeproduction',
                label: 'smokefiredetection',
                params: ['video'],
                paintType: 'polygon'
            },
            {
                id: 8,
                title: i18n.global.t('content.algType.firedetect'),
                type: 'safeproduction',
                label: 'firedetect',
                params: ['video'],
                paintType: 'polygon'
            },
            {
                id: 9,
                title: i18n.global.t('content.algType.fumesdetection'),
                type: 'safeproduction',
                label: 'fumesdetection',
                params: ['video'],
                paintType: 'polygon'
            },
            {
                id: 10,
                title: i18n.global.t('content.algType.sleepdutydetection'),
                type: 'safeproduction',
                label: 'sleepdutydetection',
                params: ['video', 'sleepduration'],
                paintType: 'polygon'
            },
            {
                id: 11,
                title: i18n.global.t('content.algType.absentdetection'),
                type: 'safeproduction',
                label: 'absentdetection',
                params: ['video', 'leaveduration', 'warnpersonnumlimit'],
                paintType: 'polygon'
            },
            {
                id: 12,
                title: i18n.global.t('content.algType.passagewayoccupy'),
                type: 'safeproduction',
                label: 'passagewayoccupy',
                params: ['video', 'pwoccupyduration'],
                paintType: 'polygon'
            },
            {
                id: 13,
                title: i18n.global.t('content.algType.goodsmoveddetetion'),
                type: 'safeproduction',
                label: 'goodsmoveddetetion',
                params: ['video', 'goodsmoveduration'],
                paintType: 'polygon'
            },
            {
                id: 14,
                title: i18n.global.t('content.algType.regionalinvasion'),
                type: 'safeproduction',
                label: 'regionalinvasion',
                params: ['video', 'occupyduration'],
                paintType: 'polygon'
            },
            {
                id: 15,
                title: i18n.global.t('content.algType.elcforbid'),
                type: 'safeproduction',
                label: 'elcforbid',
                params: ['video'],
                paintType: 'polygon'
            },
            {
                id: 16,
                title: i18n.global.t('content.algType.firetruckoccupy'),
                type: 'safeproduction',
                label: 'firetruckoccupy',
                params: ['video', 'erroccupyduration'],
                paintType: 'polygon'
            },
            {
                id: 17,
                title: i18n.global.t('content.algType.quicklymoving'),
                type: 'safeproduction',
                label: 'quicklymoving',
                params: ['video'],
                paintType: 'polygon'
            },
            {
                id: 18,
                title: i18n.global.t('content.algType.falldetection'),
                type: 'safeproduction',
                label: 'falldetection',
                params: ['video', 'fallduration'],
                paintType: 'polygon'
            },
            {
                id: 19,
                title: i18n.global.t(
                    'content.algType.pedestriananalysisregion'
                ),
                type: 'safeproduction',
                label: 'pedestriananalysisregion',
                params: [],
                paintType: 'polygon'
            },
            {
                id: 20,
                title: i18n.global.t('content.algType.areapersabndetection'),
                type: 'safeproduction',
                label: 'areapersabndetection',
                params: ['video', 'areapersabnnumlimit', 'areapersabnduration'],
                paintType: 'polygon'
            },
            {
                id: 21,
                title: i18n.global.t('content.algType.pedestriancountingline'),
                type: 'safeproduction',
                label: 'pedestriancountingline',
                params: ['direction', 'clearancetime'],
                paintType: 'line'
            },
            {
                id: 22,
                title: i18n.global.t('content.algType.reflectclothesdetection'),
                type: 'safeproduction',
                label: 'reflectclothesdetection',
                params: ['video'],
                paintType: 'polygon'
            },
            {
                id: 23,
                title: i18n.global.t('content.algType.maskdetection'),
                type: 'safeproduction',
                label: 'maskdetection',
                params: ['video'],
                paintType: 'polygon'
            },
            {
                id: 24,
                title: i18n.global.t('content.algType.climbingdetect'),
                type: 'safeproduction',
                label: 'climbingdetect',
                params: ['video', 'alarmdirection', 'polygon'],
                paintType: 'highLine'
            },
            {
                id: 25,
                title: i18n.global.t('content.algType.loiteringdetect'),
                type: 'safeproduction',
                label: 'loiteringdetect',
                params: ['video', 'loiteringduration'],
                paintType: 'polygon'
            },
            {
                id: 26,
                title: i18n.global.t('content.algType.persongathered'),
                type: 'safeproduction',
                label: 'persongathered',
                params: [
                    'video',
                    'persongatheredduration',
                    'persongatherednumlimit',
                    'persongatheredfloatnum'
                ],
                paintType: 'polygon'
            },
            {
                id: 27,
                title: i18n.global.t('content.algType.strenousexercise'),
                type: 'safeproduction',
                label: 'strenousexercise',
                params: ['video'],
                paintType: 'polygon'
            },
            {
                id: 28,
                title: i18n.global.t('content.algType.cookhatdetect'),
                type: 'safeproduction',
                label: 'cookhatdetect',
                params: ['video'],
                paintType: 'polygon'
            },
            {
                id: 29,
                title: i18n.global.t('content.algType.chefclothesdetect'),
                type: 'safeproduction',
                label: 'chefclothesdetect',
                params: ['video'],
                paintType: 'polygon'
            },
            {
                id: 30,
                title: i18n.global.t('content.algType.mousedetect'),
                type: 'safeproduction',
                label: 'mousedetect',
                params: ['video'],
                paintType: 'polygon'
            },
            {
                id: 31,
                title: i18n.global.t('content.algType.coverlanddetect'),
                type: 'safeproduction',
                label: 'coverlanddetect',
                params: ['video'],
                paintType: 'polygon'
            },
            {
                id: 32,
                title: i18n.global.t('content.algType.safetybeltdetect'),
                type: 'safeproduction',
                label: 'safetybeltdetect',
                params: ['video'],
                paintType: 'polygon'
            },
            {
                id: 33,
                title: i18n.global.t('content.algType.vehicledriveway'),
                type: 'safeproduction',
                label: 'vehicledriveway',
                params: ['video', 'detourarea', 'vehicledrivewayduration'],
                paintType: 'polygon'
            },
            {
                id: 34,
                title: i18n.global.t('content.algType.shirtlessdetect'),
                type: 'safeproduction',
                label: 'shirtlessdetect',
                params: ['video'],
                paintType: 'polygon'
            },
            {
                id: 35,
                title: i18n.global.t('content.algType.trashcanfulldetect'),
                type: 'safeproduction',
                label: 'trashcanfulldetect',
                params: ['video', 'trashduration'],
                paintType: 'polygon'
            },
            {
                id: 36,
                title: i18n.global.t('content.algType.knifestickdetect'),
                type: 'safeproduction',
                label: 'knifestickdetect',
                params: ['video'],
                paintType: 'polygon'
            },
            {
                id: 37,
                title: i18n.global.t('content.algType.allstructurevideovnpf'),
                type: 'safeproduction',
                label: 'allstructurevideovnpf',
                params: [
                    'faceenable',
                    'personenable',
                    'vehicleenable',
                    'nonvehicleenable'
                ],
                paintType: 'polygon'
            },
            {
                id: 38,
                title: i18n.global.t('content.algType.persondetection'),
                type: 'safeproduction',
                label: 'persondetection',
                params: [],
                paintType: 'polygon'
            },
            {
                id: 39,
                title: i18n.global.t('content.algType.vehicledetection'),
                type: 'safeproduction',
                label: 'vehicledetection',
                params: [],
                paintType: 'polygon'
            },
            {
                id: 40,
                title: i18n.global.t('content.algType.nonvehicledetection'),
                type: 'safeproduction',
                label: 'nonvehicledetection',
                params: [],
                paintType: 'polygon'
            }
        ],
        algFunctions: [
            {
                title: i18n.global.t('content.algType.face'),
                type: 'face'
            },
            {
                title: i18n.global.t('content.algType.fireemergency'),
                type: 'fireemergency'
            },
            {
                title: i18n.global.t('content.algType.safeproduction'),
                type: 'safeproduction'
            },
            {
                title: i18n.global.t('content.algType.chefbrightstove'),
                type: 'chefbrightstove'
            },
            { title: i18n.global.t('content.algType.vas'), type: 'vas' }
        ],
        version_mode: '' // 明厨4G:vk_4_no；明厨8G无硬盘:vk_8_no；明厨8G有硬盘:vk_8_hd；安消8G无硬盘:sf_8_no；安消8G有硬盘:sf_8_hd；工地8G无硬盘:wbs_8_no；工地8G有硬盘:wbs_8_hd；校园8G无硬盘:sy_8_no；校园8G有硬盘:sy_8_hd
    }),
    actions: {
        setLogoSync(logoSync: boolean) {
            this.logoSync = logoSync;
        },
        setTitleSync(titleSync: boolean) {
            this.titleSync = titleSync;
        },
        async getWarnList() {
            // 获取开启的算法能力
            let algFunctions = [];

            const [err, res]: any = await to(channelConfigApi.getAbility());
            if (err) {
                return;
            }
            if (res.code === 200) {
                // 算法类型，属于消防应急，还是安全生产
                const algType = Object.keys(res.data)[0];
                // 在此算法类型下，包括哪几种算法
                const algDetail: any = Object.values(res.data)[0];
                // 是否包括人脸识别算法
                for (let j of this.algEventsDetail) {
                    for (let i of algDetail) {
                        if (i === j.label && algType === j.type) {
                            algFunctions.push(j);
                        }
                    }
                }
                this.warnList = algFunctions;
            }
            let workClothName = i18n.global.t(
                'content.alarmType.noWearWorkCloth'
            );
            let passagewayoccupyName = i18n.global.t(
                'content.algType.passagewayoccupy'
            );
            const [err1, res1]: any = await to(basicApi.getClothesType());
            if (err1) {
                return;
            }
            if (res1) {
                workClothName =
                    res1.data.enableworkclothesalarm === 0
                        ? i18n.global.t('content.alarmType.noWearWorkCloth')
                        : i18n.global.t('content.algType.workclothesdetection');
            }
            const [errInfo, resInfo]: any = await to(systemApi.getBase());
            if (errInfo) {
                return;
            }
            if (resInfo) {
                this.version_mode = resInfo.data.version_mode;
                passagewayoccupyName =
                    resInfo.data.version_mode.indexOf('wbs') > -1
                        ? i18n.global.t('content.alarmType.randomMaterials')
                        : i18n.global.t('content.algType.passagewayoccupy');
                this.alarmType['18'] =
                    resInfo.data.version_mode.indexOf('wbs') > -1
                        ? i18n.global.t('content.alarmType.randomMaterials')
                        : i18n.global.t('content.algType.passagewayoccupy');
                this.algEvents.passagewayoccupy =
                    resInfo.data.version_mode.indexOf('wbs') > -1
                        ? i18n.global.t('content.alarmType.randomMaterials')
                        : i18n.global.t('content.algType.passagewayoccupy');
                sessionStorage.setItem(
                    'version_mode',
                    resInfo.data.version_mode
                );
            }
            for (let i of this.warnList) {
                if (i.label === 'workclothesdetection') {
                    i.title = workClothName;
                }
                if (i.label === 'passagewayoccupy') {
                    i.title = passagewayoccupyName;
                }
            }
            sessionStorage.setItem('warnList', JSON.stringify(this.warnList));
            return this.warnList;
        },
        toggleSideBar() {
            this.sidebar.opened = !this.sidebar.opened;
            this.sidebar.withoutAnimation = false;
            if (this.sidebar.opened) {
                Cookies.set('sidebarStatus', 1);
            } else {
                Cookies.set('sidebarStatus', 0);
            }
        },
        closeSideBar(withoutAnimation) {
            Cookies.set('sidebarStatus', 0);
            this.sidebar.opened = false;
            this.sidebar.withoutAnimation = withoutAnimation;
        },
        toggleDevice(device) {
            this.device = device;
        },
        setSize(size) {
            this.size = size;
        },
        setIdentifyRecord(fieldIdentify) {
            this.fieldIdentify = fieldIdentify;
        },
        setTheme(theme) {
            this.theme = theme;
        },
        setCompanyName(companyName) {
            this.companyName = companyName;
        },
        setLogo(logo) {
            this.logo = logo;
        },
        setBackgroundColor(backgroundColor) {
            this.backgroundColor = backgroundColor;
        },
        setLang(lang: string) {
            this.lang = lang;
        },
        setNation(nation: never[]) {
            this.nation = nation;
        },
        setProvince(province: never[]) {
            this.province = province;
        },
        setCity(city: never[]) {
            this.city = city;
        },
        setCountry(country: any) {
            this.country = country;
        },
        setIdType(idType: any) {
            this.idType = idType;
        }
    }
});
export interface PageQuery {
    page: number;
    page_size: number;
}
